import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../../actions/AuthActions';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

class PrivateRoute extends Component {

  retrieveTokenFromURL() {
    const { access_token } = qs.parse(this.props.location.search); 
    return access_token;
  }

  async validation() {

    let user = {
      codcli: this.props.computedMatch.params.site,
    };

    await this.props.login(user);

  }

  render() {
    const { user, access_token, fetching_user } = this.props;
    let token = null;

    if (!access_token) {
      token = this.retrieveTokenFromURL();
    } else {
      token = access_token;
    }

    if ((!user || !token) && fetching_user === false) {
      this.validation();
      return (<LoadingComponent />);
    }

    if (fetching_user === true) { return (<LoadingComponent />); }

    if (((!user || !token) && (fetching_user === null))) {
      return (<Redirect to={'/login'} />);
    }

    return (<Route {...this.props} component={this.props.component} />);
  }

}

const mapStateToProps = (state) => ({
  access_token: state.auth.access_token,
  user: state.auth.user,
  fetching_user: state.fetching.fetching_user
});

export default connect(mapStateToProps, { login })(withRouter(PrivateRoute));
