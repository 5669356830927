import { all } from 'redux-saga/effects';
import FilesSagas from '../components/Files/saga';
import accountSaga from '../components/Account/saga';

export function* rootSagas() {
  yield all ([
    FilesSagas(),
    accountSaga(),
  ]);
}
