import React, { Component } from 'react';
import Modal from '../Modal/Modal';
import { markAsDownloaded, fetchCDESEnCours } from '../../actions/PiecesActions';
import { connect } from 'react-redux';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const toggleModal = (piece=null) => dispatch => {
  return dispatch({type: TOGGLE_MODAL, payload: {piece}});
}

const initialState = {
  isOpen: false,
  piece: null,
};

export function confirmModalReducer(state=initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL: {
      return {...state, ...action.payload, isOpen: !state.isOpen};
      break;
    }

    default:
      return {isOpen: false, piece: null};
  }
}

class ConfirmModal extends Component {

  async onValidation() {
    let { piece, auth } = this.props;
    piece = {...piece, codsit: this.props.auth.user.codsit, codcli: auth.user.codcli};
    await this.props.markAsDownloaded(piece);
    this.props.toggleModal();
    window.location.reload();
  }

  onDismiss() {
    this.props.toggleModal();
  }

  render() {

    const { isOpen } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        msg={"Veuillez confirmer le téléchargement de la pièce par \"Oui\". Cette pièce sera disponible à partir de l'onglet \"Historique\"."}
        validationText={"Oui"}
        onValidation={() => this.onValidation()}
        dismissText={"Non"}
        onDismiss={() => this.onDismiss()}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  piece: state.confirmModal.piece,
  isOpen: state.confirmModal.isOpen,
});

export default connect(mapStateToProps, { markAsDownloaded, toggleModal, fetchCDESEnCours })(ConfirmModal);
