import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { acquitterMessage } from '../../actions/EchangeActions';
import LoadingAwaitButton from '../LoadingButton/LoadingAwaitButton';
import './echanges.css';
import { getFrDate } from '../../Utils';
import { confs } from '../../conf';
import PiecesDL from '../PiecesDL/PiecesDL';

const { PRIMARY_BG } = confs;

class Message extends Component {
  constructor(props) {
    super(props);
  }

  getInfos(message) {
    const { codope_soc, msg_date } = message;
    const { nomcli } = this.props;

    return (<small className="msg--info">Par {message.codope_soc || nomcli }, le {getFrDate(message.msg_date)}</small>);
  }

  render() {
    const { message } = this.props;

    return (
      <article className={"message "+(message.codope_soc?'ope':'client')}>
        { this.getInfos(message) }
        <p>
          <div className={"msg "+( message.row_idfixemedia?"p-0":"")} style={{backgroundColor: !message.codope_soc?PRIMARY_BG:''}}>
            {
              message.row_idfixemedia &&
                <img src={message.media.url} className={"img-fluid"} style={{borderRadius: '.3rem'}} alt=""/>
            }
            {message.msg_contenu}
          </div>
            {/*<Link style={{textDecoration: 'none'}} to={"/message/"+message.row_idfixe}>
              <i style={{color:!message.codope_soc?PRIMARY_BG:'#d7d7d7'}} className={"fa fa-eye icon-button"}></i>
            </Link>*/}
            {
              message.row_idfixemedia &&
                <PiecesDL save={message.media.web_lu_tie !== 'O'} piece={{typ_piece: 'FIL', cod_piece: message.media.num_media}} href={message.media.url}>
                  <i  style={{color:!message.codope_soc?PRIMARY_BG:'#d7d7d7'}} className={"fa fa-download icon-button"}></i>
                </PiecesDL>
            }
        </p>
      </article>
    );
  }
}

const mapStateToProps = (state) => ({
  nomcli: state.auth.user.nomcli,
  posting_image: state.fetching.posting_image,
});

export default connect(mapStateToProps, {  })(Message);
