import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchCDESEnCours, markAsDownloaded } from "../../actions/PiecesActions";
import { addConversation } from "../../actions/EchangeActions";
import { numberWithSpaces } from "../../Utils";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import AddConversationButton from "./AddConversationButton";
import "./commandes.css";
import { getFrDate, hexToRgbA } from "../../Utils";
import ListItem from "../List/ListItem";
import PiecesDL from "../PiecesDL/PiecesDL";
import { Link } from "react-router-dom";
import { COMMANDES } from "../../routes";
import { confs } from "../../conf";
const { PRIMARY_BG } = confs;

const SmallNotifBubble = ({ options }) => {
  const { background } = options;
  return (
    background && <span className={"notif small text-white bg-" + background} />
  );
};

const CommandeCard = ({ cdc }) => (
  <article>
    <div className={"commande-card--header"}>
      <div className={"commande-card--numcde"}>
        <h1>Commande n°{cdc.numcdc}</h1>
      </div>
      <span className={"date"}>
        <span style={{ display: "flex", height: "24px" }}>
          {cdc.colis && cdc.colis.length > 0 && (
            <div className={"alert alert-warning m-0 ml-2"}>Chronopost</div>
          )}
          {cdc.reliquat === "O" && (
            <div className={"alert alert-warning m-0 ml-2"}>Reliquat</div>
          )}
        </span>
        <span className={"ml-2"}>Le {getFrDate(cdc.date)}</span>
      </span>
    </div>

    <div>
      <em>{cdc.ref_cli}</em>
    </div>

    <div className={"bandeau-fonctionnel"}>
      <p className={"montant_ht m-0"}>
        {numberWithSpaces(cdc.montant_ht)}
        <span className={"devise"}> € H.T</span>
      </p>
      <div className={"tools mb-1"}>
        <AddConversationButton echange={cdc.echange} />
        {
          <Link
            style={{ position: "relative" }}
            to={COMMANDES + "/" + cdc._id_}
            className={"icon-button btn-tool"}
            onClick={(e) => { markAsDownloaded(cdc.piece)(() => {}) }}
          >
            <i className={"fa fa-share btn btn-tool text-white"} />
            {cdc.notifications.bes + cdc.notifications.medias > 0 && (
              <SmallNotifBubble options={{ background: "danger" }} />
            )}
          </Link>
        }
      </div>
    </div>
  </article>
);

const Loader = (props) => (
  <div
    style={{ height: "50px" }}
    className={"loader-container " + props.className}
  >
    <LoadingComponent />
  </div>
);

class CommandesListTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCmdIndex: 0,
    };
  }

  async UNSAFE_componentWillMount() {
    const { _id_ } = this.props.auth.user;
    const { access_token } = this.props.auth;
    await this.props.fetchCDESEnCours(_id_, access_token);
  }

  onClick(index) {
    this.setState({ ...this.state, selectedCmdIndex: index });
  }

  render() {
    const { cdes, fetching } = this.props;

    return (
      <>
        {fetching && !cdes && <Loader className={"mb-3"} />}
        {(!fetching && (!cdes || cdes.length === 0)) && <div> Pas de commande en cours </div>}
        {cdes && cdes.length > 0 && (
          <div
            style={{ display: "flex", justifyContent: "space-evenly" }}
            height="100%"
          >
            <div style={{ minWidth: "400px", width: "45%", marginTop: "30px" }}>
              {cdes.map((cdc, key) => (
                <ListItem
                  onClick={(e) => this.onClick(key)}
                  key={key}
                  id={cdc.numcdc}
                  style={{
                    backgroundColor:
                      "rgba(" +
                      hexToRgbA(PRIMARY_BG) +
                      ", " +
                      (key % 2 === 0 ? ".6" : ".4") +
                      ")",
                      border: key === this.state.selectedCmdIndex ? 'solid red' : 'none'

                  }}
                >
                  <CommandeCard cdc={cdc} />
                </ListItem>
              ))}
            </div>

            <div>
              <div style={{display:"flex", width: "100%", justifyContent:"space-evenly"}}>
                <PiecesDL
                  directDL={true}
                  documentName={"commande_" + cdes[this.state.selectedCmdIndex].numcdc + ".pdf"}
                  confirm={false}
                  save={cdes[this.state.selectedCmdIndex].piece.lu_tie != 'O'}
                  piece={cdes[this.state.selectedCmdIndex].piece}
                  href={cdes[this.state.selectedCmdIndex].pdf_file}
                  >
                   <i className="fa fa-download icon-button" style={{color: "black"}}>Télécharger</i>
                </PiecesDL>
                <PiecesDL
                  print={true}
                  confirm={false}
                  save={cdes[this.state.selectedCmdIndex].piece.lu_tie != 'O'}
                  piece={cdes[this.state.selectedCmdIndex].piece}
                  href={cdes[this.state.selectedCmdIndex].pdf_file}
                >
                  <i className="fa fa-print icon-button" style={{color: "black"}}>Imprimer</i>
                </PiecesDL>
              </div>
              <iframe
                id="frame"
                src={cdes[this.state.selectedCmdIndex].pdf_file + "#toolbar=0"}
                style={{ height: "80vh", width: "45vw" }}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  cdes: state.pieces.cdes,
  fetching: state.fetching.fetching_cde,
});

export default connect(
  mapStateToProps,
  { fetchCDESEnCours, addConversation }
)(CommandesListTest);
