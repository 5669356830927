import { combineReducers } from 'redux';
/* Reducers import */
import SwipeNavReducer from '../components/SwipeNav/reducer';
import SwipeViewsReducer from '../components/SwipeNav/reducer';
import AuthReducer from './AuthReducer';
import FetchingReducer from './FetchingReducer';
import PiecesReducer from './PiecesReducer';
import EchangeReducer from './EchangeReducer';
import { confirmModalReducer } from '../components/PiecesDL/ConfirmModal';
import DialogueReducer from '../components/Dialogues/reducer';
import DrawerReducer from '../components/Drawer/reducer';
import FilesReducer from '../components/Files/reducer';
import snackbar from '../components/Snackbar/reducer';

const createReducer = asyncReducers =>
  combineReducers({
    swipeViews: SwipeViewsReducer,
    auth: AuthReducer,
    fetching: FetchingReducer,
    pieces: PiecesReducer,
    echanges: EchangeReducer,
    confirmModal: confirmModalReducer,
    dialogues: DialogueReducer,
    drawers: DrawerReducer,
    files: FilesReducer,
    snackbar,
    ...asyncReducers,
  });

export default createReducer;
