import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchBes, markAsDownloaded } from "../../actions/PiecesActions";
import { addConversation } from "../../actions/EchangeActions";
import { numberWithSpaces, hexToRgbA } from "../../Utils";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { getFrDate } from "../../Utils";
import ListItem from "../List/ListItem";
import PiecesDL from "../PiecesDL/PiecesDL";
import { Link } from "react-router-dom";
import AddConversationButton from "./AddConversationButton";
import { confs } from "../../conf";
import "./bes.css";
const { PRIMARY_BG } = confs;

const BECard = ({ be }) => (
  <article>
    <div className={"be-card--header"}>
      <div className={"be-card--numcde"}>
        <h1>B.E n°{be.numbe}</h1>
      </div>
      <span className={"date"}>
        <span style={{ display: "flex", height: "24px" }}>
          <span className={"ml-2"}>Le {getFrDate(be.date_bl)}</span>
          {be.colis && be.colis.length > 0 && (
            <div className={"alert alert-warning m-0 ml-2"}>Chronopost</div>
          )}
        </span>
      </span>
    </div>

    <div className={"bandeau-fonctionnel"}>
      <p className={"montant_ht m-0"}>
        {numberWithSpaces(be.mt_ht)}
        <span className={"devise"}> € H.T</span>
      </p>
      <div className={"tools mb-1"}>
        <AddConversationButton echange={be.echange} />
        <Link
          style={{ textDecoration: "none" }}
          to={be.numcdc > 0 ? "/commandes/" + be.hashed_numcdc : "#"}
          onClick={(e) => { markAsDownloaded(be.piece)(() => {}) }}
        >
          <i className="icon-button fa fa-share" title="Voir la commande" />
        </Link>
      </div>
    </div>

    <div className={"be-card--content"}>
      <p className={"ellipsis"} />
    </div>
  </article>
);

const Loader = (props) => (
  <div
    style={{ height: "50px" }}
    className={"loader-container " + props.className}
  >
    <LoadingComponent />
  </div>
);

class BonsExpeditionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBEIndex: 0,
    };
  }

  async UNSAFE_componentWillMount() {
    const { _id_ } = this.props.auth.user;
    const { access_token } = this.props.auth;
    let res = await this.props.fetchBes(_id_, access_token);
  }
  onClick(index) {
    this.setState({ ...this.state, selectedBEIndex: index });
  }
  render() {
    const { bes, fetching } = this.props;
    return (
      <>
        {fetching && <Loader className={"mb-3"} />}
        {!bes || (bes.length === 0 && <div>Pas de bons d'expédition.</div>)}
        {bes && bes.length > 0 && (
          <div
            style={{ display: "flex", justifyContent: "space-evenly" }}
            height="100%"
          >
            <div style={{ minWidth: "400px", width: "45%", marginTop: "30px" }}>
              {bes.map((be, key) => (
                <ListItem
                  onClick={(e) => this.onClick(key)}
                  key={key}
                  id={be.numbe}
                  style={{
                    backgroundColor:
                      "rgba(" +
                      hexToRgbA(PRIMARY_BG) +
                      ", " +
                      (key % 2 === 0 ? ".6" : ".4") +
                      ")",
                      border: key === this.state.selectedBEIndex ? 'solid red' : 'none'

                  }}
                >
                  <BECard be={be} />
                </ListItem>
              ))}
            </div>
            <div >
              <div style={{display:"flex", width: "100%", justifyContent:"space-evenly"}}>

              <PiecesDL
                directDL={true}
                save={bes[this.state.selectedBEIndex].piece.lu_tie != 'O'}
                documentName={"bon_expedition_" + bes[this.state.selectedBEIndex].numbe + ".pdf"}
                confirm={false}
                piece={bes[this.state.selectedBEIndex].piece}
                href={bes[this.state.selectedBEIndex].pdf_file}
                >
                 <i className="fa fa-download icon-button" style={{color: "black"}}>Télécharger</i>
              </PiecesDL>
              <PiecesDL
                print={true}
                save={bes[this.state.selectedBEIndex].piece.lu_tie != 'O'}
                confirm={false}
                piece={bes[this.state.selectedBEIndex].piece}
                href={bes[this.state.selectedBEIndex].pdf_file}
              >
                <i className="fa fa-print icon-button" style={{color: "black"}}>Imprimer</i>
              </PiecesDL>
              
                </div>
              <iframe
                id="frame"
                src={bes[this.state.selectedBEIndex].pdf_file + "#toolbar=0"}
                style={{ height: "80vh", width: "45vw" }}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bes: state.pieces.bes,
  fetching: state.fetching.fetching_bes,
});

export default connect(
  mapStateToProps,
  { fetchBes, addConversation }
)(BonsExpeditionList);
