import React, { Component } from 'react';
import './factures.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchFacturesHisto } from '../../actions/PiecesActions';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import LoadingAwaitButton from '../LoadingButton/LoadingAwaitButton';
import {getFrDate, updateSizeView, numberWithSpaces, hexToRgbA} from '../../Utils';
import AddConversationButton from './AddConversationButton';
import PiecesDL from '../PiecesDL/PiecesDL';
import { Accordion, AccordionItem } from '../Accordion';
import List from '../List/List';
import ListItem from '../List/ListItem';
import {confs} from '../../conf';
const { PRIMARY_BG } = confs;

const Facture = ({ fac, month}) => (
  <article>
    <div className={"facture-card--header"}>
      <div className={"facture-card--numcde"}>
        <h1>
          Facture n°{fac.numfac}
        </h1>
      </div>
      <span className={"date"}>

        <span className={"reliquat"}>
        {(
          fac.foua === 'A' &&
          (
            <div className={"alert alert-warning"}>
            Avoir
            </div>
          )
        )}
        </span>

        <span style={{color: 'red', padding: '0 1rem'}}>
          {month}
        </span>
        Le {getFrDate(fac.date_fac)}
      </span>
    </div>

    <div className={"bandeau-fonctionnel"}>
      <p style={{marginBottom: '.5rem'}} className={"montant_ht m-0"}>      
          {numberWithSpaces(fac.mt_ht)}<span className={"devise"}> € H.T</span>
      </p>
      <div className={"tools"}>
        <AddConversationButton echange={fac.echange} />
        <Link style={{textDecoration: 'none'}} to={fac.numcdc > 0 ? ("/commandes/" + fac.hashed_numcdc) : '#'}>
          <i className="icon-button fa fa-share" title="Voir la commande"></i>
        </Link>
      </div>
    </div>

    <div className={"facture-card--content"}>
      <p className={"montant_ht"}>
      </p>
    </div>
  </article>
);

const Loader = (props) => (
  <div style={{height: '50px'}} className={"loader-container "+props.className}>
    <LoadingComponent />
  </div>
);

class FacturesHisto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      selectedFacIndex: 0,
      selectedYearIndex: 0,
    };
  }

  onClick(yearIndex, facIndex) {
    this.setState({ ...this.state, selectedFacIndex: facIndex, selectedYearIndex: yearIndex });
  }

  async UNSAFE_componentWillMount() {
    const { codcli } = this.props.auth.user;
    const { access_token } = this.props.auth;

    let res = await this.props.fetchFacturesHisto(codcli, {access_token});
    updateSizeView();
  }

  async loadMore() {
    await this.props.fetchFacturesHisto(
      this.props.auth.user.codcli, {
        access_token: this.props.auth.access_token,
        all: true
      }
    );
    this.setState({isLoaded: true});
  }

  render() {
    const { fetching, fac_histo } = this.props;
    const months = ["JAN", "FEV", "MAR", "AVR", "MAI", "JUIN", "JUI", "AOU", "SEP", "OCT", "NOV", "DEC"];
    let ruptureMois = 0;

    return (
      <>
        <div 
          className={"container"}
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-evenly",
            margin: 0,
            padding: 0,
            maxWidth: "100%",
            minWidth: "100%"
          }}
        >
          {(fetching) && <Loader className={"mb-3"} />}
          {
            (!fetching && fac_histo) &&
              <div style={{minWidth: "400px", width: "45%"}}>
                {
                  fac_histo.map((year, yearIndex) => (
                    <div key={year.year}>
                      <div>
                        <Accordion label={year.year} defaultIsOpen={yearIndex === 0}>
                          <List>
                          {
                            year.factures.map((facture, factureIndex)=>{
                              let date = new Date(facture.date_fac)
                              if (factureIndex===0) { ruptureMois = date.getMonth()}
                              if (ruptureMois !== date.getMonth()){
                                ruptureMois = date.getMonth();
                                return (
                                  <>
                                  <div key={facture.numfac} >
                                    <div  className={"separator"}></div>
                                    <ListItem onClick={(e) => this.onClick(yearIndex, factureIndex)} style={{backgroundColor: 'rgba('+hexToRgbA(PRIMARY_BG)+', '+ (factureIndex%2===0?'.6':'.4')+')',border: factureIndex === this.state.selectedFacIndex && yearIndex === this.state.selectedYearIndex ? 'solid red' : 'none'}}>
                                        <Facture month={months[date.getMonth()]} fac={facture} first={factureIndex===0?' first':null} last={factureIndex===year.factures.length-1?' last':null} />
                                    </ListItem>
                                  </div>
                                  </>
                                )
                              } else {
                                return (
                                  <ListItem onClick={(e) => this.onClick(yearIndex, factureIndex)} key={facture.numfac} style={{backgroundColor:'rgba('+hexToRgbA(PRIMARY_BG)+', '+ (factureIndex%2===0?'.6':'.4')+')',border: factureIndex === this.state.selectedFacIndex && yearIndex === this.state.selectedYearIndex ? 'solid red' : 'none'}}>
                                      <Facture month={months[date.getMonth()]} fac={facture} first={factureIndex===0?' first':null} last={factureIndex===year.factures.length-1?' last':null} />
                                  </ListItem>
                                )
                              }
                            })
                          }
                          </List>
                        </Accordion>
                      </div>
                    </div>
                  ))
                } 
              </div>
          }
          {
            (!fetching && fac_histo && fac_histo[this.state.selectedYearIndex]) && (
              <div>
                <div style={{display:"flex", width: "100%", justifyContent:"space-evenly"}}>
                  <PiecesDL
                    directDL={true}
                    documentName={"facture_" + fac_histo[this.state.selectedYearIndex].factures[this.state.selectedFacIndex].numfac + ".pdf"}
                    confirm={false}
                    save={fac_histo[this.state.selectedYearIndex].factures[this.state.selectedFacIndex].piece.lu_tie != 'O'}
                    piece={fac_histo[this.state.selectedYearIndex].factures[this.state.selectedFacIndex].piece}
                    href={fac_histo[this.state.selectedYearIndex].factures[this.state.selectedFacIndex].pdf_file}
                    >
                     <i className="fa fa-download icon-button" style={{color: "black"}}>Télécharger</i>
                  </PiecesDL>
                  <PiecesDL
                    print={true}
                    confirm={false}
                    save={fac_histo[this.state.selectedYearIndex].factures[this.state.selectedFacIndex].piece.lu_tie != 'O'}
                    piece={fac_histo[this.state.selectedYearIndex].factures[this.state.selectedFacIndex].piece}
                    href={fac_histo[this.state.selectedYearIndex].factures[this.state.selectedFacIndex].pdf_file}
                  >
                    <i className="fa fa-print icon-button" style={{color: "black"}}>Imprimer</i>
                  </PiecesDL>
                </div>
                <iframe
                  title="frame"
                  id="frame"
                  src={fac_histo[this.state.selectedYearIndex].factures[this.state.selectedFacIndex].pdf_file + "#toolbar=0"}
                  style={{ height: "80vh", width: "45vw" }}
                />
              </div>
            )
          }
          {
            (!fetching && (!fac_histo || fac_histo.length <= 0)) &&
              <div style={{padding: '1rem'}}>Pas de factures en historique</div>
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  fac_histo: state.pieces.fac_histo,
  fetching: state.fetching.fetching_fac
});

export default connect(mapStateToProps, { fetchFacturesHisto })(FacturesHisto);
