import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from '../../actions/AuthActions';
import { withRouter, Link } from 'react-router-dom';
import './login-form.css';
import LoadingAwaitButton from '../LoadingButton/LoadingAwaitButton';
import { confs } from '../../conf';
import history from '../../history';

const { URL_GED, PRIMARY_BG } = confs;

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  async login () {
    this.setState({error: null});
    let res = await this.props.login(this.state);
    if(res.payload !== null ) {
      /* Successfully authentificate */
      history.push('/');
    } else {
      this.setState({
        error: 'Erreur d\'authentification'
      });
    }
  }

  render() {

    const { children } = this.props

    return (
        <div>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img src={URL_GED+'/main-logo.jpg'} className={"img-fluid shadow mb-5"} alt=""/>
          </div>

          <article>
            <div >
             <label>Identifiant</label>
             <input
               name={"codcli"}
               onChange={(e)=>this.handleChange(e)}
               className={"form-control"} />

             <label>Mot de passe</label>
             <input
               name={"pwd"}
               type={"password"}
               onChange={(e)=>this.handleChange(e)}
               className={"form-control"} />

             {
               (this.state.error) &&
               <div style={{width: '100%'}} className={"alert alert-danger text-center mt-2"}>
                 {this.state.error}
               </div>
             }

            {/* HERE GOES THE BUTTON*/}
            <LoadingAwaitButton
              className={"btn btn-block btn-connexion my-2"}
              onClick={()=>this.login()}
              style={{backgroundColor: PRIMARY_BG}}
            >
              Se connecter
            </LoadingAwaitButton>

            <Link to={"/mot-de-passe-oublie"}>J'ai oublié mon mot de passe</Link>

            </div>
          </article>


        </div>
    );
  }
}

export default withRouter(connect(null, { login })(LoginForm));
