import {
    FETCH_CDES_ENCOURS,
    FETCH_CDE_DETAIL,
    FETCH_CDES_HISTO,
    FETCH_BES,
    FETCH_BES_HISTO,
    FETCH_MEDIAS,
    FETCH_MEDIAS_HISTO,
    FETCH_FACTURES,
    FETCH_FACTURES_HISTO,
    MEDIA_VALIDATION,
  } from '../actions/PiecesActions';

const initialState = {
  cdes: [],
  cdes_histo: [],
  fac: [],
  fac_histo: [],
  bes: [],
  bes_histo: [],
  cde_detail: {},
  medias: {},
  medias_histo: {},
}

export default function (state=initialState, action) {
  switch (action.type) {
    case FETCH_CDES_ENCOURS:
      return {...state, cdes: action.payload};
    case FETCH_CDES_HISTO:
      return {...state, cdes_histo: action.payload};
    case FETCH_BES:
      return {...state, bes: action.payload};
    case FETCH_BES_HISTO:
      return {...state, bes_histo: action.payload};
    case FETCH_MEDIAS:
      return {...state, medias: action.payload};
    case FETCH_FACTURES:
      return {...state, fac: action.payload};
    case FETCH_FACTURES_HISTO:
      return {...state, fac_histo: action.payload};
    case FETCH_MEDIAS_HISTO:
    return {...state, medias_histo: action.payload};
    case FETCH_CDE_DETAIL:
      return {...state, cde_detail: action.payload};
    case MEDIA_VALIDATION: {
      let index = state.cde_detail.sharedFiles.findIndex((item) => item.row_idfixemedia == action.payload.row_idfixemedia);
      let newState = state;
      newState.cde_detail.sharedFiles[index] = {...newState.cde_detail.sharedFiles[index], ...action.payload};
      return newState;
    }
    case 'POST_IMAGE_START':
      return {
          ...state,
          cde_detail: {
              ...state.cde_detail,
              isFileLoading: true,
          }
      }

    case 'POST_IMAGE_SUCCESS':
      return {
          ...state,
          cde_detail: {
              ...state.cde_detail,
              sharedFiles: [...state.cde_detail.sharedFiles, action.payload ],
              isFileLoading: false,
          }
      }
    case 'POST_IMAGE_FAILED':
      return {
          ...state,
          cde_detail: {
              ...state.cde_detail,
              isFileLoading: false,
          }
      }
    default:
      return state;
  }
}
