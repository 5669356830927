import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { ripple } from './ripple';
import { confs } from '../../conf';
import { hexToRgbA } from '../../Utils';
import './style.css';

const { PRIMARY_BG } = confs;

class RippleButton extends Component {
  state = {
    width: 0,
    height: 0,
  };

  componentDidMount() {
    if (this.rippleButton) {
      this.rippleButton.addEventListener('click', ripple);
    }
  }

  onClick(e) {
    e.preventDefault();
    const { onClick } = this.props;
    // Handle the given function
    if (onClick) { onClick(); }
  }

  render () {

    const { children, style, className } = this.props;
    return (
      <div
        ref={rippleButton => this.rippleButton=rippleButton}
        className={"ripple-effect "+(className)}
        style={style}
        onClick={(e) => this.onClick(e)}
      >
        { children }
      </div>
    );
  }
}

export default RippleButton;
