import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAll, getOne } from './actions';
import { NotificationsZone, NotificationBubble } from '../Notifications';
import './style.css';

const DialogueLink = ({dialogue, onClick}) => (
  <div
    onClick={() => onClick()}
    className={"dialogue-link"}>
    <h1>
      {dialogue.lib_echange || (dialogue.typ_piece+" | "+dialogue.cod_piece)}
    </h1>
    <NotificationsZone>
      {(dialogue.nbr_msg_nonlu_soc > 0 && <NotificationBubble background={'primary'} number={dialogue.nbr_msg_nonlu_soc} />)}
      {(dialogue.nbr_msg_nonlu_tie > 0 && <NotificationBubble background={'success'} number={dialogue.nbr_msg_nonlu_tie} />)}
    </NotificationsZone>
  </div>
);

class List extends Component {
  state = {
    active: 0
  }
  UNSAFE_componentWillMount() {
    const { auth } = this.props;
    const params = {
      codcli: auth.user._id_,
      access_token: auth.access_token
    };
    this.props.getAll(params);
  }

  loadConversation(one, key) {
    if (key === this.state.active) { return };
    this.props.getOne({
      num_echange: one.num_echange,
      access_token: this.props.auth.access_token
    });
    this.setState({ active: key });
  }

  render () {
    const { all, fetching, fetched } = this.props.list;

    return (
      <div className={"conversation-list"}>
        { fetching && <em>Chargement en cours</em>}
        <ul>
          { all && all.map((one, key) => (
            <li key={key}>
              <DialogueLink
                dialogue={one}
                className={key===this.state.active?'active':''}
                onClick={() => this.loadConversation(one, key)
                }
              />
            </li>
          )) }
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  list: state.dialogues.list
});

export default connect(mapStateToProps, { getAll, getOne })(List);
