import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addConversation } from '../../actions/EchangeActions';
import LoadingAwaitButton from '../LoadingButton/LoadingAwaitButton';
import './echanges.css';
import history from '../../history';
import { confs } from '../../conf';

const { PRIMARY_BG } = confs;

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      error: null,
    });

  }

  async addConversation() {
    if (!this.state.lib_echange) { return this.setState({error: "Le sujet ne peut être vide."}); }
    if (this.state.lib_echange === '...') { return this.setState({error: "Impossible de créer l'échange '...'."}); }

    const params = {
      lib_echange: this.state.lib_echange,
      cod_tiers: this.props.user.codcli,
      codsit: this.props.user.codsit,
    }
    const { access_token } = this.props.auth;

    let res = await this.props.addConversation(params, access_token);

    const { num_echange } = res.payload;
    if(num_echange) {
      history.push('/echanges');
    }
  }

  render() {

    const { message } = this.props;

    return (
        <section className={"my-3 py-2 px-2"}>
          <div style={{fontSize:"18px"}} className={"my-3 text-center text-muted"}>Démarrer un échange</div>
          <div className="input-group">
            <input className="form-control py-4" placeholder="A quel sujet?" name={"lib_echange"} onChange={(e) => this.handleChange(e)} type={"text"} value={this.state.lib_echange} />
              <div className="input-group-append">
                <LoadingAwaitButton className={"btn input-button-icon"} style={{backgroundColor: PRIMARY_BG}} onClick={()=>this.addConversation()}>
                  <i className={"fa fa-pen"}></i>
                </LoadingAwaitButton>
              </div>
            </div>
            {(this.state.error) && <div className={"alert alert-danger my-3"}>{this.state.error}</div>}
        </section>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  auth: state.auth,
});

export default connect(mapStateToProps, { addConversation })(Conversation);
