import React, { Component } from 'react';
import { confs } from '../../conf';
import './style.css';

const { URL_GED } = confs;

const images = [
  URL_GED + '/bannieres/img_ban3.jpg',
  URL_GED + '/bannieres/img_ban4.jpg',
  URL_GED + '/bannieres/img_ban5.jpg',
  URL_GED + '/bannieres/img_ban6.jpg',
  URL_GED + '/bannieres/img_ban7.jpg',
];

class Carousel extends Component {
  state={index: 0};

  UNSAFE_componentWillMount() {
    const interval = setInterval(() => this.next(), 3000);
  }

  prev() {
    const { index } = this.state;
    this.setState({
      index: index < images.length-1 ? index+1: 0
    });
  }

  next() {
    const { index } = this.state;
    this.setState({
      index: index > 0 ? index-1: images.length-1
    });
  }

  render() {

    const { index } = this.state;
    const { paginationControl } = this.props;
    const img = this.props.images? this.props.images: images;

    return (
      <div  className={"d2c--carousel"}>

          <div
            className={"d2c--carousel-slides"}
            style={{backgroundImage: 'url('+img[index]+')'}}
          ></div>

          {(paginationControl && (
            <div className={"navigation"}>
              <button onClick={() => this.prev()}><i className={"fa fa-angle-left"}></i></button>
              <button onClick={() => this.next()}><i className={"fa fa-angle-right"}></i></button>
            </div>
          ))}
      </div>
    );
  }
}

export default Carousel;
