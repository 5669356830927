import React, { Component } from 'react';
import LoginForm from '../components/LoginForm/LoginForm';

class Login extends Component {

  render() {
    return (
      <main id={"login-page"} className={"container"}>
        <div className={"row justify-content-center"}>
          <div className={"col-md-6 px-5"}>
            <LoginForm />
          </div>
        </div>
      </main>
    );
  }
}

export default Login;
