import React, { Component } from "react";
import { Link } from "react-router-dom";
import { HeaderNotif } from "./NotificationPanel";
import "./style.css";

class ATelechargerNotifications extends Component {
  render() {
    const { commandes, bes, factures } = this.props.aTelecharger;
    return (
      <div>
        {(commandes.length || 0) + (bes.length || 0) + (factures.length || 0) >
          0 && (
          <HeaderNotif icon={"download"} background={"danger"}>
            {(commandes.length || 0) +
              (bes.length || 0) +
              (factures.length || 0)}{" "}
            pièce(s)
          </HeaderNotif>
        )}
        <div className="ml-3">
          <Link to="/commandes?swiper_index=0" className="notif-link-i">
            Commandes en cours ({commandes.length}){" "}
            <i className="fa fa-arrow-right ml-2" />
          </Link>
        </div>
        <div className="ml-3">
          <Link to="/bons-expedition?swiper_index=0" className="notif-link-i">
            Bons d'expédition à télécharger ({bes.length}){" "}
            <i className="fa fa-arrow-right ml-2" />
          </Link>
        </div>
        <div className="ml-3">
          <Link to="/factures?swiper_index=0" className="notif-link-i">
            Factures à télécharger ({factures.length}){" "}
            <i className="fa fa-arrow-right ml-2" />
          </Link>
        </div>
        {/* {
          commandes.length > 0 &&
            <NotifsList id="notifs-commandes-list" label={"Commandes en cours (" + commandes.length + ")"}>
              {
                commandes.map((commande) => (
                  <NotifItem
                    // attachTo={"# Voir dans la liste"}
                    attachTo_link={commande.attachTo_link}
                    visit={commande.visit}
                    date={commande.date}
                    icon={'share'}
                  >
                    Commande n°{commande.numcdc}
                  </NotifItem>
                ))
              }
            </NotifsList>
        }
        {
          medias.length > 0 &&
            <NotifsList id="notifs-medias-list" label={"Documents (" + medias.length + ")"}>
              {
                medias.map((media) => (
                  <NotifItem
                    visit={media.visit}
                    date={media.date}
                    icon={"share"}
                  >
                    {media.name}
                  </NotifItem>
                ))
              }
            </NotifsList>
        }
        {
          bes.length > 0 &&
            <NotifsList id="notifs-bes-list" label={"Bons d'expéditions non facturés (" + bes.length + ")"}>
              {
                bes.map((be) => (
                  <NotifItem
                    visit={be.visit}
                    date={be.date_bl}
                    // attachTo={"# Voir dans la liste"}
                    attachTo_link={be.attachTo_link}
                    icon={"share"}
                  >
                    Bons d'expédition n°{be.numbe}
                  </NotifItem>
                ))
              }
            </NotifsList>
        }
        {
          factures.length > 0 &&
            <NotifsList id="notifs-factures-list" label={"Factures à télécharger (" + factures.length + ")"}>
              {
                factures.map((facture) => (
                  <NotifItem
                    // attachTo={"# Voir dans la liste"}
                    attachTo_link={facture.attachTo_link}
                    visit={facture.visit}
                    date={facture.date_fac}
                    icon={"share"}
                  >
                    Facture n°{facture.numfac}
                  </NotifItem>
                ))
              }
            </NotifsList>
        } */}
      </div>
    );
  }
}

export default ATelechargerNotifications;
