import React, { Component } from 'react';
import Drawer from '../Drawer/Drawer';
import { Legend, LegendItem } from './';

class LegendDrawer extends Component {
  render() {

    const { children } = this.props;

    return (
      <Drawer position={"top"} drawer={"drawer-info"}>
        <div className={"container"} style={{padding: '0'}}>
          <Legend>
            Notifications
            <LegendItem color={"danger"}>
              Pièce(s) à télécharger.
            </LegendItem>
            <LegendItem color={"success"}>
              Message(s) à lire.
            </LegendItem>
            <LegendItem color={"primary"}>
              Message(s) en attente d'être lu(s).
            </LegendItem>
          </Legend>
          <Legend>
            Symboles
            <LegendItem icon={"comment"}>
              Accéder ou créer un échange lié à la pièce courante.
            </LegendItem>
            <LegendItem icon={"share"}>
              Voir les pièce(s) relatives à la pièce courante.
            </LegendItem>
            <LegendItem icon={"download"}>
              Télécharger une pièce.
            </LegendItem>
            <LegendItem icon={"truck"}>
              Voir le(s) numéro(s) de suivi 'Chronopost'.
            </LegendItem>
            <LegendItem icon={"trash"}>
              Supprimer un document.
            </LegendItem>
          </Legend>
        </div>
      </Drawer>
    );
  }
}

export default LegendDrawer;
