import React, { Component } from 'react';
import BoxesMessage from '../components/Echanges/BoxesMessage';
import MessageForm from '../components/Echanges/MessageForm';
import SubHeader from './SubHeader';
import Fade from '@material-ui/core/Fade';
import { Dialog } from '@material-ui/core';
import Detail from '../components/Dialogues/Detail';
import { isMobile } from '../Utils';

function Transition(props) {
  return <Fade  {...props} />;
}

class EchangesPage extends Component {
  render() {
    return (
      <main id={"contact-page"}>

        <Dialog
           fullWidth
           fullScreen={isMobile()}
           open={true}
           TransitionComponent={Transition}
         >

             <BoxesMessage />

         </Dialog>

      </main>
    );
  }
}

export default EchangesPage;
