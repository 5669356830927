import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
class ListItem extends Component {
  componentDidMount() {
    const params = qs.parse(this.props.location.search.replace('?', '')) || 0;
    let el = document.getElementById(params.show);
    if (el) {
      let top = el.getBoundingClientRect();
      if (top.top > 700) {
        document.getElementsByClassName('views--c-slides')[0].scrollTo(0, top.top-100);
      }
    }
  }
  render() {
    const { children, id, style } = this.props;
    const show = qs.parse(this.props.location.search.replace('?', '')) || 0;
    if (show) {
      if (show.show == id) {
        let el = document.getElementById(id);
        if (el && this.item) {
          this.item.classList.add('show-ilsduc');
        }
      }
    }
    return (
      <div
        onClick={e => this.props.onClick && this.props.onClick(e)}
        ref={item => this.item = item}
        id={id}
        className={"list-item--component scale-effect"}
        style={{
          ...style,
        }}
      >
        { children }
      </div>
    );
  }
}

export default withRouter(ListItem);
