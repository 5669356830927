
import React, { Component } from 'react';
import DesactivateAccount from './DesactivateAccount';
import { confs } from '../../conf';
import { connect } from 'react-redux';
import { resetPassword } from './actions';

const { PRIMARY_BG } = confs;

const style = {
  title: {
    fontSize: '1.2rem',
    color: PRIMARY_BG,
    marginBottom: '1rem',
  },
  formBox: {
    padding: '1rem',
    borderRadius: '.3rem',
    boxShadow: '0 0 7px 0 rgba(0,0,0,.15)',
    backgroundColor: 'white',
    marginBottom: '1rem',
  },
};

class Account extends Component {
  state = {
    pwd: '',
    access_ok: this.props.user.gestion_web === 'O',
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      error: null
    });
  }

  changePassword() {
    if (this.state.pwd.length <= 0 || this.state.pwd !== this.state.confirm) {
      this.setState({
        error: 'Un ou plusieurs champs sont vides ou les mots de passe ne correspondent pas.',
      });
      return;
    }
    this.props.resetPassword({pwd: this.state.pwd, code: this.props.user.codcli});
  }

  render() {
    const { user } = this.props;
    return (
      <div className={"container"}>
        <div className={"row justify-content-center"}>
          <div className={"col-md-6"} style={{borderLeft: '3px solid '+PRIMARY_BG, borderRadius: '3px'}}>
            <div className={"p-3"}>

              <div style={style.title}>
                Réinitialiser mon mot de passe
              </div>
              <div style={style.formBox}>
                <input onChange={(e) => this.handleChange(e)} name={"current_pwd"} value={this.state.current_pwd} placeholder={"Mot de passe actuel"} type="password" className={"form-control mb-2"}/>
                <input onChange={(e) => this.handleChange(e)} name={"pwd"} value={this.state.pwd} placeholder={"Nouveau mot de passe"} type="password" className={"form-control mb-2"}/>
                <input onChange={(e) => this.handleChange(e)} name={"confirm"} value={this.state.confirm} placeholder={"Confirmer"} type="password" className={"form-control mb-2"}/>
              </div>
              {
                this.state.error &&
                  <div className={"alert alert-danger"}>
                    {this.state.error}
                  </div>
              }
              <button onClick={() => this.changePassword()} style={{backgroundColor: PRIMARY_BG, color: 'white'}} className={"btn mb-2"}>Réinitialiser</button>

              <hr style={{margin: '1rem'}}/>

              <DesactivateAccount account={user.codcli} />

            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { resetPassword })(Account);
