import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../actions/AuthActions';
import Drawer from '../Drawer/Drawer';
import ToggleDrawer from '../Drawer/ToggleDrawer';

class MainDrawer extends Component {
  render() {
    const items = [
      {title: 'Mon profil', icon: 'user', to: '/mon-profil'},
      {separator: true},
      {title: 'Mes commandes', icon: 'shopping-cart', to: '/commandes'},
      {title: 'Mes bons d\'expédition', icon: 'location-arrow', to: '/bons-expedition'},
      {title: 'Mes factures', icon: 'credit-card', to: '/factures'},
      {separator: true},
      {title: 'Se déconnecter', icon: 'power-off', onClick: () => this.props.logout()},
    ];

    const { children } = this.props;

    return (
      <Drawer
        position={"right"}
        drawer={"main-drawer"}
        title={"Accueil"}
        items={items}
      >

      </Drawer>
    );
  }
}

export const ToggleMainDrawer = () => (
  <ToggleDrawer drawer={"main-drawer"} icon={"bars"}/>
);

export default connect(null, { logout })(MainDrawer);
