import React, { Component } from 'react';
import { connect } from 'react-redux';
import PiecesDL from '../PiecesDL/PiecesDL';
import AddConversationButton from './AddConversationButton';
import SuiviColisModal from './SuiviColisModal';
import { hexToRgbA, getFrDate } from '../../Utils';
import { confs } from '../../conf';

const { PRIMARY_BG } = confs;

const style = {
  card: {
    backgroundColor: 'rgba('+hexToRgbA(PRIMARY_BG)+', .4)',
    borderRadius: '.3rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    margin: '0 .5rem',
  },
  montant_ht : {
    color: 'white',
    fontWeight: '400',
    fontSize: '2rem',
  },
  tools: {
    display: 'flex',
    alignItems: 'center',
  },
};

class CommandeDetailCard extends Component {
  render() {
    const { cdc, isSelected } = this.props;
    return (
    <>
      <div className={"container"}>
        <div className={"row justify-content-center"}>
          <div className={"col-md-12"}>
            <div style={{...style.card, border: isSelected ? 'solid red' : 'none'}}>

              <div style={style.montant_ht}>
                <p style={{fontSize: '1.5rem', fontWeight: 'bold'}}>Commande n° {cdc.numcdc}</p>
                <div style={{fontSize: '0.9rem'}}>
                  <em>
                    {cdc.ref_cli}
                  </em>
                </div>
                {cdc.montant_ht}
                <span style={{fontSize: '1.7rem'}}> €</span>
                <span style={{fontSize: '1rem'}}> H.T</span>
                <div style={{fontSize: '1rem'}}>
                  {cdc.date}
                </div>
              </div>
              {
                (cdc && cdc.echange) &&
                  (
                    <div style={style.tools}>
                      <AddConversationButton echange={cdc.echange} />
                    </div>
                  )
                }
            </div>
          </div>
        </div>
      </div>

      <div className={'container'} style={{padding: 0}}>

        <div className={"row justify-content-center"}>
          <div className={"col-md-8"}>
            <hr style={{margin: '0 2rem'}}/>
          </div>
        </div>

      </div>
    </>
    );
  }
}

export default CommandeDetailCard;
