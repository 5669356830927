import React, { Component } from 'react';
import NavHeader from '../components/NavHeader/NavHeader';
import CommandeDetailCard from '../components/Commandes/CommandeDetailCard';
import Fade from '@material-ui/core/Fade';
import ConfirmModal from '../components/PiecesDL/ConfirmModal';
import { ToggleLegendDrawer, LegendDrawer } from '../components/Legend';
import PiecesDL from '../components/PiecesDL/PiecesDL';
import LoadingComponent from '../components/LoadingComponent/LoadingComponent';
import PiecesList from '../components/Commandes/PiecesList';
import FacturesList from '../components/Commandes/FacturesList';
import history from '../history';
import { connect } from 'react-redux';
import { fetchCdeDetail } from '../actions/PiecesActions';
import AddFileDrawer from '../components/DrawersApp/AddFileDrawer';
import MainDrawer, { ToggleMainDrawer } from '../components/DrawersApp/MainDrawer';
import PiecesLoader from '../components/Files/PiecesLoader';

const CommandeDetailHeader = ({ numcdc, fetching }) => (
  <NavHeader
    onPressBack={() => history.goBack()}
    title={"Commande n°"+((numcdc && !fetching)?numcdc:' ...')}
    style={{position: 'fixed', width: '100%', zIndex: 10, left: '0', top: '0'}}
  >
    <ToggleMainDrawer />
    <ToggleLegendDrawer />
  </NavHeader>
);

class CommandeDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDoc: props.cde_detail,
      selectedDocName: "commande",
      selectedDocNum: props.cde_detail && props.cde_detail.numcdc
    };
  }

  UNSAFE_componentWillMount() {
    const { numcdc } = this.props.match.params;
    const { access_token } = this.props;
    this.props.fetchCdeDetail(
      numcdc,
      access_token,
    );
  }

  onClick(doc, docName, docNum) {
    this.setState({ ...this.state, selectedDoc: doc, selectedDocName: docName, selectedDocNum: docNum });
  }

  render() {
    const { cde_detail, fetching_cde } = this.props;

    return (
        <Fade in={true} mountOnEnter unmountOnExit>
          <div style={{padding: '72px 0 5rem 0'}}>
            <CommandeDetailHeader numcdc={cde_detail && cde_detail.numcdc} fetching={fetching_cde} />
            {
              (fetching_cde && <LoadingComponent />)
            }
            <div style={{display: "flex", flexDirection: "row"}}>
              <div style={{width: "50%"}}>
                {
                  (!fetching_cde && cde_detail) &&
                    <div onClick={(e) => this.onClick(cde_detail, "commande", cde_detail.numcdc)} >
                      <CommandeDetailCard cdc={cde_detail} isSelected={this.state.selectedDocName === "commande"} />
                    </div>
                }
                {
                  (!fetching_cde && cde_detail) &&
                    <div>
                      <PiecesList pieces={cde_detail.bes} onClickCallback={this.onClick.bind(this)} selectedDocName={this.state.selectedDocName} selectedDocNum={this.state.selectedDocNum} />
                    </div>
                }
                {
                  (!fetching_cde && cde_detail) &&
                    <div>
                      <FacturesList factures={cde_detail.factures} onClickCallback={this.onClick.bind(this)} selectedDocName={this.state.selectedDocName} selectedDocNum={this.state.selectedDocNum} />
                    </div>
                }
                {
                  (!fetching_cde && cde_detail && cde_detail.piece) &&
                    <PiecesLoader
                      piece={cde_detail.piece}
                      pathFile={"documents/"+cde_detail.codsit+"/"+cde_detail.codcli+"/"+cde_detail.piece.typ_piece+"/"+cde_detail.piece.cod_piece}
                    />
                }
              </div>
              <div style={{width: "50%"}}>
                {
                  (!fetching_cde && this.state.selectedDoc && this.state.selectedDoc.piece) &&
                    <div style={{width: "100%"}} >
                      <div style={{display:"flex", width: "100%", justifyContent:"space-evenly"}}>
                        <PiecesDL
                          directDL={true}
                          documentName={this.state.selectedDocName + "_" + this.state.selectedDocNum + ".pdf"}
                          confirm={false}
                          save={this.state.selectedDoc.piece.lu_tie != 'O'}
                          piece={this.state.selectedDoc.piece}
                          href={this.state.selectedDoc.pdf_file}
                          >
                           <i className="fa fa-download icon-button" style={{color: "black"}}>Télécharger</i>
                        </PiecesDL>
                        <PiecesDL
                          print={true}
                          confirm={false}
                          save={this.state.selectedDoc.piece.lu_tie != 'O'}
                          piece={this.state.selectedDoc.piece}
                          href={this.state.selectedDoc.pdf_file}
                        >
                          <i className="fa fa-print icon-button" style={{color: "black"}}>Imprimer</i>
                        </PiecesDL>
                      </div>
                      <iframe
                        title="frame"
                        id="frame"
                        src={this.state.selectedDoc.pdf_file + "#toolbar=0"}
                        style={{ height: "80vh", width: "50vw" }}
                      />
                    </div>
                }
              </div>
            </div>

            <ConfirmModal />

            <LegendDrawer />

            <MainDrawer />

            <AddFileDrawer />

          </div>
        </Fade>
    )
  }
}

const mapStateToProps = (state) => ({
  cde_detail: state.pieces.cde_detail,
  access_token: state.auth.access_token,
  fetching_cde: state.fetching.fetching_cde
});

export default connect(mapStateToProps, { fetchCdeDetail })(CommandeDetail);
