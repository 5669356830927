import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LayoutViews from '../components/SwipeNav/LayoutViews';
import EchangesEnCours from '../components/Echanges/EchangesEnCours';
import EchangesHisto from '../components/Echanges/EchangesHisto';
import EchangeForm from '../components/Echanges/EchangeForm';
import NavHeader from '../components/NavHeader/NavHeader';
import Fade from '@material-ui/core/Fade';
import ToggleDrawer from '../components/Drawer/ToggleDrawer';
import Drawer from '../components/Drawer/Drawer';
import { ToggleLegendDrawer, LegendDrawer } from '../components/Legend';
import EchangesDrawer, { ToggleEchangesDrawer } from '../components/DrawersApp/EchangesDrawer';
import { confs } from '../conf';

const { PRIMARY_BG } = confs;

const EchangesHeader = () => (
  <NavHeader title={"Echanges"} >
    <ToggleEchangesDrawer />
    <ToggleLegendDrawer />
  </NavHeader>
);

const navs = [
  {title: 'Libres', icon: 'envelope', animated: true},
  {title: 'Historique', icon: 'hourglass-end', animated: true},
];

const views = [
  EchangesEnCours,
  EchangesHisto,
];

class Echanges extends Component {

  render() {
    return (
      <Fade in={true}  mountOnEnter unmountOnExit>
        <>
          <LayoutViews primary={PRIMARY_BG} header={EchangesHeader} navs={navs} views={views} />

          <EchangesDrawer />
          <LegendDrawer />
        </>
      </Fade>
    );
  }
}

export default Echanges;
