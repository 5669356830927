import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { closeDrawer } from './actions';
import { confs } from '../../conf';
import './style.css';
import RippleEffect from '../Ripple/RippleEffect';

const { PRIMARY_BG } = confs;

class Drawer extends Component {
  render() {
    const { children, items, title } = this.props;
    const { position, drawer, drawers } = this.props;
    const className = drawers[drawer]?'open':'';

    return (
      <div id={"drawer-navigation"} className={className}>
        <div onClick={() => this.props.closeDrawer(drawer)} id={"drawer-overlay--c"}>
        </div>
        <nav id={"drawer--c"} className={position}>
          <div className={"clickable-zone"}>
            <div
              onClick={() => this.props.closeDrawer(drawer)}
              style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '.5rem',
                  backgroundColor: PRIMARY_BG,
                  color: 'white',
                  textAlign: 'center',
                  cursor: 'pointer'
              }}
            >
              Fermer
              {/*<div className={'ml-2 text-muted'}>{title && title}</div>*/}
              {/*<div className={"icon-button"} style={{color: PRIMARY_BG}}  onClick={() => this.props.closeDrawer(drawer)}>
                <i className={"fa fa-times"}></i>
              </div>*/}
            </div>
            { children }
            {
              items &&
              <ul>
                {items.map((item, key) => (
                  <RippleEffect key={key}>
                    <li className={"drawer-item"} key={key} onClick={() => {!item.keepOpen && this.props.closeDrawer(drawer)}}>
                      {item.to &&
                          <Link to={item.to}>
                            {item.icon && <i className={"fa mr-2 fa-"+(item.icon)}> </i>}  {item.title}
                          </Link>
                      }
                      {
                        item.onClick &&
                          <a  onClick={() => item.onClick()}>
                            {item.icon && <i className={"fa mr-2 fa-"+(item.icon)}> </i>}  {item.title}
                          </a>
                      }
                      {
                        item.separator &&
                          <hr />
                      }
                    </li>
                  </RippleEffect>

                ))}
              </ul>
            }
          </div>
            <div style={{bottom: "0", position: "absolute", left: "50%", transform:"translateX(-50%)"}}>version 1.2.0</div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  drawers: state.drawers
});

export default connect(mapStateToProps, { closeDrawer })(Drawer);
