import React, { Component } from 'react';
import './style.css';

class Legend extends Component {
  render() {

    const { children } = this.props;

    return (
      <div className={"legend"}>

        {children}

      </div>
    );
  }
}

export default Legend;
