import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.css';


class Modal extends Component {

  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.canScroll(nextProps)
  }

  UNSAFE_componentWillMount() {
    this.canScroll(this.props);
  }

  canScroll(props) {
    if (props.isOpen === true) {
      document.body.classList.add('overflow-y-h');
    } else {
      document.body.classList.remove('overflow-y-h');
    }
  }

  validation() {
    this.props.onValidation();
  }

  dismiss() {
    this.props.onDismiss();
  }

  render() {

    const { isOpen } = this.props;
    const { msg, onValidation, onDismiss, validationText, dismissText } = this.props;

    return (
        <div id="modal--overlay" className={isOpen === true ? 'active': ''}>
          <div className="container">
            <div id="rating-modal">
              <div className="content">
                <p className={"text-dark"}>{msg}</p>
              </div>
              <div id="rating-modal--button-box">
                {(onValidation && <button onClick={() => this.validation()}>{validationText}</button>)}
                {(onDismiss && <button onClick={() => this.dismiss()}>{dismissText}</button>)}
              </div>
            </div>
          </div>
        </div>
    );
  }

}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Modal);
