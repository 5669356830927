import React, { Component } from 'react';
import { withLocalStorage } from '../withLocalStorage';

class ToggleJustification extends Component {

  render() {

    const { setState } = this.props;

    return (
      <input onChange={(e) => setState({"checkbox-ec48zfeg": e.target.value})} type="checkbox"/>
    );
  }
}

export default withLocalStorage('checkbox-ec48zfeg')(ToggleJustification);
