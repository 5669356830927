import React, { Component } from 'react';
import Slide from '@material-ui/core/Slide';
import { Dialog } from '@material-ui/core';
import history from '../../history';
import { confs } from '../../conf';

const { PRIMARY_BG } = confs;

function Transition(props) {
  return <Slide direction={"up"}  {...props} />;
}

const style = {
    title: {
      padding: '1rem',
    },
    list: {
      listStyleType: 'none',
      margin: '0',
      padding: '0',
    },
    link: {
      display: 'block',
      padding: '.5rem',
      textAlign: 'center',
    }
};

class SuiviColisModal extends Component {

  state = {
    open: false
  };

  render() {
    const { primary } = this.props;
    return (
      <>
        <div onClick={() => this.setState({open: true})} className={"icon-button"} style={primary?{color: PRIMARY_BG}:{}}>
          <i className={"fa fa-truck"}></i>
        </div>

        <Dialog
           fullWidth
           open={this.state.open}
           onClose={() => this.setState({open: false})}
           TransitionComponent={Transition}
         >
          <div style={style.title}>Suivez vos colis</div>
          <ul style={style.list}>
            {
                this.props.colis.map((coli, key) => (
                  <li key={key}>
                    <a style={style.link} href={coli.link} target={"_blank"}>
                      {coli.no_suivi}
                    </a>
                  </li>
                ))
            }
          </ul>
          <button onClick={() => this.setState({open: false})} className={"btn btn-link"}>Fermer</button>
         </Dialog>
       </>
    );
  }
}

export default SuiviColisModal;
