import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import construction from '../img/under-construction.svg';
import { confs } from '../conf';
import history from '../history';

const { URL_API } = confs;

class PageEnConstruction extends Component {

state = {};

  UNSAFE_componentWillMount() {
    fetch(URL_API+'/files/'+this.props.match.params.numcdc)
      .then(res => res.json())
      .then(data => this.setState({
        files: data.data
      }));
  }

  render() {

    const { files } = this.state;

    return (
      <div id={"under-construction"}>

      {(files &&
        <a
          href={files[0].cdc_pdf}
          className={"btn btn-block btn-link-type"}
          style={{maxWidth: '300px'}}
          target={"_blank"}
        >
          Télécharger la commande en pdf
        </a>
      )}

        <div className={"text-center"}>
          Page en construction... Bons d'expéditions et BAT(s) à venir!
        </div>

        <img src={construction} className={"img-fluid"} style={{maxWidth: '300px'}} />

        <div
          className={"btn btn-block btn-link-type"}
          style={{maxWidth: '300px'}}
          onClick={() => history.goBack()}
        >
          Retour
        </div>
      </div>
    );
  }
}

export default withRouter(PageEnConstruction);
