import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import RippleEffect from '../Ripple/RippleEffect';
import { HeaderNotif } from './NotificationPanel';
import NotifsList, { NotifItem } from './NotifsList';

class MsgNonLuSocNotifications extends Component {

  render() {
    const { commandes, bes, factures } = this.props.msgNonLuSoc;
    return (
      <div>
        {
          (commandes.length || 0) + (bes.length || 0) + (factures.length || 0) > 0 &&
            <>
              <hr style={{margin: '1rem'}}/>
              <HeaderNotif icon={"comments"} background={'primary'}>
                Votre interlocuteur a {(commandes.length || 0) + (bes.length || 0) + (factures.length || 0)} échange(s) à consulter
              </HeaderNotif>
            </>
        }
        {
          commandes.length > 0 &&
            <NotifsList id={"notifs-msg-nonlu-soc-commandes"} label={"Sur " + commandes.length + " commandes"}>
              {
                commandes.map((commande) => (
                  <NotifItem attachTo_link={commande.visit} date={commande.dtemodif} icon={"comment"}>
                    Sujet: {commande.lib_echange}
                  </NotifItem>
                ))
              }
            </NotifsList>
        }
        {
          bes.length > 0 &&
            <NotifsList id={"notifs-msg-nonlu-soc-bes"} label={"Sur " + bes.length + " bons d'expéditions"}>
              {
                bes.map((be) => (
                  <NotifItem attachTo_link={be.visit} date={be.dtemodif} icon={"comment"}>
                    Sujet: {be.lib_echange}
                  </NotifItem>
                ))
              }
            </NotifsList>
        }
        {
          factures.length > 0 &&
            <NotifsList id={"notifs-msg-nonlu-soc-factures"} label={"Sur " + factures.length + " factures"}>
              {
                factures.map((facture) => (
                  <NotifItem attachTo_link={facture.visit} date={facture.dtemodif} icon={"comment"}>
                    Sujet: {facture.lib_echange}
                  </NotifItem>
                ))
              }
            </NotifsList>
        }

      </div>
    );
  }
}


export default MsgNonLuSocNotifications;
