import React, { Component } from 'react';
import Header from './Header';
import { Link } from 'react-router-dom';
import DesktopIcon from '../components/DesktopIcon/DesktopIcon';
import EchangeFloatButton from '../components/Echanges/EchangeFloatButton';
import Carousel from '../components/Carousel/Carousel';
import logo from '../img/expeditions.png';
import cupcake from '../img/cupcake.svg';
import NotificationPanel from '../components/Notifications/NotificationPanel';
import { LegendDrawer } from '../components/Legend';
import MainDrawer from '../components/DrawersApp/MainDrawer';
import ToggleJustification from './ToggleJustification';

class Main extends Component {
  render() {
    return (
        <div>
          <Header />
          <section className={"main--carousel-container"}>
            <Carousel />
          </section>

          <div className={"container"}>
            <div id={"desktop-icon--container"}>

              {/*<DesktopIcon path={"/commandes"} label={"Commandes"} letter={"c"} notifs_src={"commandes"} />
              <DesktopIcon path={"/factures"} label={"Factures"} letter={"f"} notifs_src={"factures"}/>*/}

            </div>
            {/*<hr style={{margin: '1rem'}}/>*/}
            <NotificationPanel />
          </div>

          <EchangeFloatButton />

          <MainDrawer />
          <LegendDrawer />

        </div>
    );
  }
}

export default Main;
