import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { confs } from '../../conf';
import { NotificationsZone, NotificationBubble } from '../Notifications';

const { URL_API, PRIMARY_BG } = confs;

class EchangeFloatButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    let codcli = this.props.auth.user._id_;
    let { access_token } = this.props.auth;

    fetch(URL_API+'/notifications-echanges/'+codcli+'?access_token='+access_token)
    .then(res => res.json())
    .then(data => this.setState({notifs: data.data}));
  }

  render() {

    const { url } = this.props.match;
    const { notifs } = this.state;

    return (
      <div className={"float-button-box"}>
        <Link to={"/echanges"} className={"mx-2"} id={"echange-float-button"} style={{backgroundColor: PRIMARY_BG}}>
          <NotificationsZone noMargin>
            {((notifs && notifs.a_lire > 0) && <NotificationBubble background={"success"} number={notifs.a_lire} />)}
            {((notifs && notifs.non_lu > 0) && <NotificationBubble background={"primary"} number={notifs.non_lu} />)}
          </NotificationsZone>
          <i className={"fa fa-2x fa-comments"}></i>
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps, null)(EchangeFloatButton));
