import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../actions/AuthActions';
import Drawer from '../Drawer/Drawer';
import ToggleDrawer from '../Drawer/ToggleDrawer';

class EchangesDrawer extends Component {
  render() {
    const items = [
      {title: "Démarrer un nouvel echange", icon: "plus-circle", to: "/echanges/add"},
    ];

    const { children } = this.props;

    return (
      <Drawer
        position={"right"}
        drawer={"echanges-drawer"}
        title={"Echanges"}
        items={items}
      >

      </Drawer>
    );
  }
}

export const ToggleEchangesDrawer = () => (
  <ToggleDrawer drawer={"echanges-drawer"} icon={"bars"}/>
);

export default connect(null, { logout })(EchangesDrawer);
