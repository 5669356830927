import {
  TOGGLE_DRAWER,
  OPEN_DRAWER,
  CLOSE_DRAWER,
} from './actions';

const initialState = {};

export default function (state=initialState, action) {
  switch (action.type) {
    case TOGGLE_DRAWER: {
      if (!state[action.payload] === true)
        { document.body.style.overflowY = 'hidden' }
      else
        { document.body.style.overflowY = 'auto' }
      return {...state, [action.payload]: !state[action.payload] };
    }
    case OPEN_DRAWER: {
      { document.body.style.overflowY = 'hidden' }
      return {...state, [action.payload]: true };
    }
    case CLOSE_DRAWER: {
      { document.body.style.overflowY = 'auto' }
      return {...state, [action.payload]: false };
    }
    default:
      return state;
  }
};
