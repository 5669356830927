import React, { Component } from 'react';
import ToggleDrawer from '../Drawer/ToggleDrawer';

class ToggleLegendDrawer extends Component {
  render() {

    const { children } = this.props;

    return (
      <ToggleDrawer icon={"info-circle"} drawer={"drawer-info"} />
    );
  }
}

export default ToggleLegendDrawer;
