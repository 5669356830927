import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchMessages } from '../../actions/EchangeActions';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import { getFrDate } from '../../Utils';
import { withRouter } from 'react-router-dom';
import Message from './Message';
import MessageForm from './MessageForm';
import SoldeButton from './SoldeButton';
import SubHeader from '../../pages/SubHeader';
import history from '../../history';
import { confs } from '../../conf';
import ConversationFileLoader from '../Files/ConversationFileLoader';

const { PRIMARY_BG } = confs;

const FileLoading = () => (
  <i id={"file-loading"} className={"fa fa-file-pdf fa-2x text-align"}></i>
);

class BoxesMessage extends Component {
  constructor(props) {
    super(props);
    this.state = this.props;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.posting_image !== this.props.posting_image) {
      return;
    }

    if (this.state.newMessage !== nextProps.newMessage && (nextProps.newMessage.msg_contenu || nextProps.newMessage.row_idfixemedia)) {
      let echange=this.state.echange;
      echange.nbr_msg_nonlu_soc += 1;
      echange.messages= echange.messages===false? [] : echange.messages;
      echange.messages.push(nextProps.newMessage);
      this.setState({...this.state, echange });
      return;
    }

    if(nextProps.echange) {
      this.setState({echange: nextProps.echange});
      return;
    }
  }

  async UNSAFE_componentWillMount() {
    const { num_echange } = this.props.match.params;
    const { access_token } = this.props.auth;
    let echange = await this.props.fetchMessages(num_echange, access_token);
    let element = document.getElementById('conversation-box');
    if (element) {
      element.scrollTo({
        top: 10000000000
      });
    }

    /* Envoie de la requete de notification des messages comme vu, sur le dernier qui ne lui appartient pas ! */
  }

  render() {
    const { echange } = this.state;
    const { fetching_messages } = this.props;

    return (
      <div className={"modal-conv"}>

        {
          <header className={"conversation-header"} style={{backgroundColor: PRIMARY_BG}}>
            <div className={"ellipsis"} style={{display: 'flex', alignItems: 'center'}}>
              <div onClick={() => history.goBack()} className={"icon-button"}>
                <i className={"fa fa-angle-left"}></i>
              </div>
              <div id={"conversation-title"}>
                { !fetching_messages && echange.lib_echange }
              </div>
            </div>
            {
              (!fetching_messages && echange.nbr_msg_nonlu_soc <= 0 ) &&
                <SoldeButton />
            }
          </header>
        }

        <div id={"conversation-box"}>
          <div className={"container"}>
            {

              (fetching_messages || !echange) && <LoadingComponent />

            ||

              echange.messages && echange.messages.map((message, key) => (
                <div key={key} className={"row justify-content-center no-gutters"}>
                  <div className={"col-md-12"}>
                    <Message message={message} />
                  </div>
                </div>
              ))
            }
            {
              this.props.posting_image &&
                <div style={{display: 'flex', color: PRIMARY_BG, flexDirection: 'row-reverse', padding: '2rem'}}>
                  <FileLoading />
                </div>
            }
          </div>

        </div>

        <MessageForm echange={echange&&echange} />

        <ConversationFileLoader pathFile={"documents/"+echange.codsit+"/"+this.props.auth.user.codcli+"/echanges/"+echange.num_echange} echange={echange&&echange} />

        
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  echange: state.echanges.echange,
  newMessage: state.echanges.newMessage,
  fetching_messages: state.fetching.fetching_messages,
  posting_image: state.fetching.posting_image,
});

export default withRouter(connect(mapStateToProps, { fetchMessages })(BoxesMessage));
