import React, { Component } from 'react';

class NotificationBubble extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    const { background, number } = this.props;
    return (
      <span className={"notification-bubble bg-"+background}>
        {number}
      </span>
    );
  }
}

export default (NotificationBubble);
