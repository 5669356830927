import React, { Component } from 'react';
import { updateSizeView } from '../../Utils';
import { confs } from '../../conf';
import './style.css';

const { PRIMARY_BG } = confs;

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state= {
      isOpen: props.defaultIsOpen || false
    }
  }

  toggle() {
      this.setState({
        isOpen: !this.state.isOpen
      }, () => updateSizeView());
  }

  render() {

    const { children, label } = this.props;
    const { isOpen } = this.state;

    return (
      <div className={"accordion"} >
        <div style={{color: PRIMARY_BG, borderColor: PRIMARY_BG}} className={"accordion--toggler"+(isOpen?' open':'')}  onClick={() => this.toggle()}>
            <span className={"label"}>{ label }</span> <i className={"fa fa-angle-"+(isOpen?'down':'right')}></i>
        </div>
        { isOpen && children }
      </div>
    );
  }
}

export default Accordion;
