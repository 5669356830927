import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchBesHisto } from '../../actions/PiecesActions';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import LoadingAwaitButton from '../LoadingButton/LoadingAwaitButton';
import {getFrDate, updateSizeView, numberWithSpaces, hexToRgbA} from '../../Utils';
import AddConversationButton from './AddConversationButton';
import PiecesDL from '../PiecesDL/PiecesDL';
import { Accordion, AccordionItem } from '../Accordion';
import List from '../List/List';
import ListItem from '../List/ListItem';
import {confs} from '../../conf';
const { PRIMARY_BG } = confs;

const Commande = ({ be, month, date_demarrage }) => (
  <article>

    <div className={"facture-card--header"}>
      <div className={"facture-card--numcde"}>
        <h1>
          B.E n°{be.numbe}
        </h1>
      </div>
      <span className={"date"}>

        <span className={"reliquat"}></span>

        <span style={{color: 'red', padding: '0 1rem'}}>
          {month}
        </span>
        Le {getFrDate(be.date_bl)}
      </span>
    </div>

    <div className={"bandeau-fonctionnel"}>
      <div style={{marginBottom: '.5rem'}}>
        <div style={{margin: '0'}} className={"montant_ht"}>
          {numberWithSpaces(be.mt_ht)}<span className={"devise"}> € H.T</span>
        </div>
      </div>
      {
          <div className={"tools"}>
            <AddConversationButton echange={be.echange} />
            <Link style={{textDecoration: 'none'}} to={(be.numcdc > 0 ? "/commandes/"+be.hashed_numcdc : '#')}>
              <i className="icon-button fa fa-share" title="Voir la commande"></i>
            </Link>
          </div>
      }

    </div>

    <div className={"facture-card--content"}>
    </div>
  </article>
);

const Loader = (props) => (
  <div style={{height: '50px'}} className={"loader-container "+props.className}>
    <LoadingComponent />
  </div>
);

class BonsExpeditionHisto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      selectedBeIndex: 0,
      selectedYearIndex: 0,
    };
  }

  onClick(yearIndex, beIndex) {
    this.setState({ ...this.state, selectedBeIndex: beIndex, selectedYearIndex: yearIndex });
  }


  async UNSAFE_componentWillMount() {
    const { _id_ } = this.props.auth.user;
    const params = {
      access_token: this.props.auth.access_token,
    };

    let res = await this.props.fetchBesHisto(_id_, params);
  }

  async loadMore() {
    await this.props.fetchBesHisto(
      this.props.auth.user._id_, {
        access_token: this.props.auth.access_token,
        all: true
      }
    );
    this.setState({isLoaded: true});
  }

  render() {
    const { fetching, bes_histo } = this.props;
    const months = ["JAN", "FEV", "MAR", "AVR", "MAI", "JUIN", "JUI", "AOU", "SEP", "OCT", "NOV", "DEC"];
    let ruptureMois = 0;

    if ((this.props.active !== 1) && !bes_histo) { return null }

    // updateSizeView();

    return (

      <>
        <div 
          className={"container"}
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-evenly",
            margin: 0,
            padding: 0,
            maxWidth: "100%",
            minWidth: "100%"
          }}
        >
          {(fetching) && <Loader className={"mb-3"} />}
          {
            (!fetching && bes_histo) &&
              <div style={{minWidth: "400px", width: "45%"}}>
                {
                  bes_histo.map((year, yearIndex) => (
                    <div key={yearIndex}>
                      <div>
                        <Accordion label={year.year} defaultIsOpen={yearIndex === 0}>
                          <List>
                          {
                            year.bes.map((be, beIndex)=>{
                              let date = new Date(be.date_bl)
                              if (beIndex===0) { ruptureMois = date.getMonth()}
                              if (ruptureMois !== date.getMonth()){
                                ruptureMois = date.getMonth();
                                return (
                                  <>
                                    <div className={"separator"}></div>
                                    <ListItem key={yearIndex.toString() + beIndex.toString()} onClick={(e) => this.onClick(yearIndex, beIndex)} style={{backgroundColor:'rgba('+hexToRgbA(PRIMARY_BG)+', '+ (beIndex%2===0?'.6':'.4')+')', border: beIndex === this.state.selectedBeIndex && yearIndex === this.state.selectedYearIndex ? 'solid red' : 'none'}}>
                                        <Commande month={months[date.getMonth()]} date_demarrage={this.props.auth.user.web_date_mail} be={be} />
                                    </ListItem>
                                  </>
                                )
                              } else {
                                return (
                                  <ListItem key={yearIndex.toString() + beIndex.toString()} onClick={(e) => this.onClick(yearIndex, beIndex)} style={{backgroundColor:'rgba('+hexToRgbA(PRIMARY_BG)+', '+ (beIndex%2===0?'.6':'.4')+')', border: beIndex === this.state.selectedBeIndex && yearIndex === this.state.selectedYearIndex ? 'solid red' : 'none'}}>
                                      <Commande month={months[date.getMonth()]} be={be} />
                                  </ListItem>
                                )
                              }
                            })
                          }
                          </List>
                        </Accordion>
                      </div>
                    </div>
                  ))
                }
              </div>
          }
          {
            (!fetching && bes_histo && bes_histo[this.state.selectedYearIndex]) && (
              <div>
                <div style={{display:"flex", width: "100%", justifyContent:"space-evenly"}}>
                  <PiecesDL
                    directDL={true}
                    documentName={"bon_expedition_" + bes_histo[this.state.selectedYearIndex].bes[this.state.selectedBeIndex].numbe + ".pdf"}
                    confirm={false}
                    save={bes_histo[this.state.selectedYearIndex].bes[this.state.selectedBeIndex].piece.lu_tie != 'O'}
                    piece={bes_histo[this.state.selectedYearIndex].bes[this.state.selectedBeIndex].piece}
                    href={bes_histo[this.state.selectedYearIndex].bes[this.state.selectedBeIndex].pdf_file}
                    >
                     <i className="fa fa-download icon-button" style={{color: "black"}}>Télécharger</i>
                  </PiecesDL>
                  <PiecesDL
                    print={true}
                    confirm={false}
                    save={bes_histo[this.state.selectedYearIndex].bes[this.state.selectedBeIndex].piece.lu_tie != 'O'}
                    piece={bes_histo[this.state.selectedYearIndex].bes[this.state.selectedBeIndex].piece}
                    href={bes_histo[this.state.selectedYearIndex].bes[this.state.selectedBeIndex].pdf_file}
                  >
                    <i className="fa fa-print icon-button" style={{color: "black"}}>Imprimer</i>
                  </PiecesDL>
                </div>
                <iframe
                  title="frame"
                  id="frame"
                  src={bes_histo[this.state.selectedYearIndex].bes[this.state.selectedBeIndex].pdf_file + "#toolbar=0"}
                  style={{ height: "80vh", width: "45vw" }}
                />
              </div>
            )
          }
          {
            (!fetching && bes_histo && bes_histo.length <= 0) &&
              <div style={{padding: '1rem'}}>Pas de bons d'expédition en historique</div>
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bes_histo: state.pieces.bes_histo,
  fetching: state.fetching.fetching_cde,
  active: state.swipeViews.active
});

export default connect(mapStateToProps, { fetchBesHisto })(BonsExpeditionHisto);
