import React, { Component } from 'react';
import './style.css';

class LoadingAwaitButton extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, disabled: false };
  }

  async handleClick() {
    this.setState({isLoading: true, disabled: true});

    await this.props.onClick();

    this.setState({isLoading: false, disabled: false});

    if (this.props.onEnd) {
      this.props.onEnd();
    }
  }

  render() {

    const { children, className, id, style } = this.props
    const { isLoading, disabled } = this.state;

    return (
           <div
            onClick={()=>this.handleClick()}
            className={className}
            id={id}
            disabled={disabled}
            style={style}
            >
              {
                (isLoading===true) &&
                (
                  <div className={"loader-container"}>
                    <div className={"flexbox justify-content-center align-items-center"}>
                      <div className={"btn-loader"}>
                        <div className={"large-circle"}></div>
                        <div className={"small-circle"}></div>
                      </div>
                    </div>
                  </div>
                )
                ||
                (children)
              }
            </div>
    );
  }
}

export default LoadingAwaitButton;
//
