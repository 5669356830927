import axios from 'axios';
import qs from 'qs';
import { confs } from '../../conf';

const { URL_API } = confs;

const account = {
  changePassword: (password) =>
    axios.post(URL_API+'/reset-password', qs.stringify(password))
      .then(res => res.data.data),
  changeAccess: (access_ok) =>
    axios.post(URL_API+'/change-access', qs.stringify(access_ok))
    .then(res => res.data.data),
  forgotPassword: (code) =>
    axios.post(URL_API+'/forgot-password', qs.stringify(code))
    .then(res => res.data.data),
};

export { account };
