import axios from 'axios';
import qs from 'qs';
import { confs } from '../../conf';

const { URL_API } = confs;

export const files = {
  postImage: (params) => {
    var formData = new FormData();
    formData.append("file", params.file[0]);
    formData.append("pathFile", params.pathFile);
    formData.append("cod_piece", params.cod_piece);
    formData.append("typ_piece", params.typ_piece);
    formData.append("rename", params.rename?params.rename:'');
    if (params.codope_tie) { formData.append("codope_tie", params.codope_tie||null); }
    if (params.codope_soc) { formData.append("codope_soc", params.codope_soc||null); }
    return axios.post(URL_API+'/cdes/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    }).then(res => res.data.data);
  },
  deleteFile: (params) =>
    axios.get(URL_API+'/files/delete', {params})
      .then(res => res.data.data),
};
