import React, { Component } from 'react';
import RippleEffect from '../Ripple/RippleEffect';
import { Link } from 'react-router-dom';
import { confs } from '../../conf';
import { isMobile, getFrDate } from '../../Utils';

const { URL_API, PRIMARY_BG } = confs;

const style = {
  item: {
    background: 'white',
    boxShadow: '0 0 7px 0 rgba(0,0,0,.15)',
    borderRadius: isMobile() ? '0' : '.3rem',
    marginLeft: isMobile()? '0' : '1rem',
    padding: '1rem',
    cursor: 'pointer',
    display: 'block',
    textDecoration: 'none',
    position: 'relative',
  },
  date: {
    color: 'rgba(0,0,0,.4)',
    fontSize: '0.7rem',
  },
  attachTo: {
    color: 'rgba(0,0,0,.4)',
    fontSize: '0.7rem',
    marginLeft: isMobile()? '.2rem' : '1rem',
  },
  collapse_link : {
    display: 'block',
    padding: '.5rem 1rem',
    cursor: 'pointer',
  },
  icon: {
    position: 'absolute',
    top: '0',
    right: '0',
    color: 'rgba(0,0,0,.225)',
    margin: '.5rem'
  }
};

export const NotifItem = ({ children, visit, date, attachTo, attachTo_link, icon }) => (
  <div className="col-md-3 pb-2 pt-2">
    {
      (attachTo_link) &&
        <Link to={attachTo_link} style={style.attachTo}>
          {attachTo}
        </Link>
    }
    <a href={attachTo_link}  style={style.item} className={"notif-item--link ellipsis"}>
      {icon && <span style={style.icon}> <i className={"fa fa-"+icon}></i> </span>}
      { children }
      <div style={style.date}>{getFrDate(date)}</div>
    </a>
  </div>
);

class NotifsList extends Component {
  render() {
    const { children, label, id } = this.props;
    return (
      <div className={"notifs-list"}>
        <a className="text-muted" style={style.collapse_link} data-toggle="collapse" href={"#"+id} role="button" aria-expanded="false" aria-controls={id}>
          { label }
          <i className="fa fa-caret-right ml-2"></i>
        </a>
        <div id={id} className={"row collapse"}>
          { children }
        </div>
      </div>
    );
  }
}

export default NotifsList;
