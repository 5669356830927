import React, { Component } from 'react';
import './style.css';
import history from '../../history';
import { confs } from '../../conf';

const { PRIMARY_BG } = confs;

class NavHeader extends Component {
  render() {
    const { children, title } = this.props;
    return (
        <header id={"nav-header"} style={{backgroundColor: PRIMARY_BG, ...this.props.style}}>
          <nav style={{display: 'flex'}} className={"ellipsis"}>
            <div style={{minWidth: '40px'}} onClick={this.props.onPressBack?this.props.onPressBack:()=>history.push('/')} className={"icon-button"}>
              <i className={"fa fa-angle-left"}></i>
            </div>
            <div id={"nav-header--title"} className={"ml-2 ellipsis"}>
              {title}
            </div>
          </nav>
          <div style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'center'}}>
            {children}
          </div>
        </header>
    );
  }
}

export default NavHeader;
