import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchEchangesHisto } from '../../actions/EchangeActions';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import EchangeForm from './EchangeForm';
import { Link } from 'react-router-dom';
import './echanges.css';
import { getFrDate } from '../../Utils';
import { NotificationsZone, NotificationBubble } from '../Notifications';
import List from '../List/List';
import ListItem from '../List/ListItem';
import { confs } from '../../conf';
import RippleEffect from '../Ripple/RippleEffect';

const { PRIMARY_BG } = confs;

const ConvCard = ({ echange }) => {

  return (
    <RippleEffect>
      <Link className={"item--link"} to={"/echanges/"+(echange.num_echange)}>
        <article className={"conv-card"} style={{position: 'relative'}}>

          <div className={"avatar-card"} style={{backgroundColor: PRIMARY_BG, position: 'relative'}}>
            {(echange.lib_echange && echange.lib_echange.substr(0,1).toUpperCase()) || (echange.typ_piece+" | "+echange.cod_piece).substr(0,1).toUpperCase()}
            <NotificationsZone noMargin>
              {(echange.nbr_msg_nonlu_soc > 0 && <NotificationBubble background={'primary'} number={echange.nbr_msg_nonlu_soc} />)}
              {(echange.nbr_msg_nonlu_tie > 0 && <NotificationBubble background={'success'} number={echange.nbr_msg_nonlu_tie} />)}
            </NotificationsZone>
          </div>

          <div className={"conversation-card--header"} style={{width: '100%'}}>
            <div className={"conversation-card--numcde"}>
              <h1 className={"ellipsis"} style={{maxWidth: '100%'}}>
                {echange.lib_echange || (echange.typ_piece+" | "+echange.cod_piece)}
              </h1>
            </div>

            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
              <small className={"date"}>
                <em>{echange.nbr_msg_total} message(s)</em>
              </small>
              <small>
                <small>
                  {(echange.nbr_msg_nonlu_soc>0 || echange.nbr_msg_nonlu_tie>0) && <span>{getFrDate(echange.dtemodif)}</span>}
                </small>
              </small>
            </div>
          </div>

        </article>
      </Link>
    </RippleEffect>
  );
}
//


class EchangesEnCours extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async UNSAFE_componentWillMount() {
    const { _id_ } = this.props.auth.user;
    const { access_token } = this.props.auth;
    await this.props.fetchEchangesHisto(_id_, access_token);
  }

  render() {
    const { echanges, fetching } = this.props;

    if (fetching || !echanges) { return <LoadingComponent />}

    return (
      <div className={"container"}>
        <div className={"row justify-content-center no-gutters"}>
          <div className={"col-md-8"}>
              {
                echanges.map((echange, key) => (
                  <>
                    <ConvCard  echange={echange} />
                  </>
                ))
              }
              { echanges.length <= 0 && (<div className={"text-center"}>Pas d'échange dans l'historique</div>)}

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  echanges: state.echanges.echanges_histo,
  fetching: state.fetching.fetching_echanges
});

export default connect(mapStateToProps, { fetchEchangesHisto })(EchangesEnCours);
