export const FETCH_DIALOGUES = 'FETCH_DIALOGUES';
export const FETCH_DIALOGUES_SUCCESS = 'FETCH_DIALOGUES_SUCCESS';
export const FETCH_DIALOGUES_FAILED = 'FETCH_DIALOGUES_FAILED';

export const getAll = (params) => ({
  type: FETCH_DIALOGUES, params: params
});

export const FETCH_CONV = 'FETCH_CONV';
export const FETCH_CONV_SUCCESS = 'FETCH_CONV_SUCCESS';
export const FETCH_CONV_FAILED = 'FETCH_CONV_FAILED';

export const getOne = (params) => ({
  type: FETCH_CONV, params: params
});
