import {
  FETCH_ECHANGE_ENCOURS,
  FETCH_ECHANGE_HISTO,
  FETCH_MESSAGES,
  ADD_MESSAGE,
  CLOTURER_CONVERSATION,
  POST_IMAGE,
  FETCH_MESSAGE,
} from '../actions/EchangeActions';

const initialState = {
  echanges_encours: [],
  echanges_histo: [],
  echange: [],
  newMessage: {},
  msg_detail: {},
}

export default function (state=initialState, action){
  switch (action.type) {
    case FETCH_ECHANGE_ENCOURS:
      return {...state, echanges_encours: action.payload};

    case FETCH_ECHANGE_HISTO:
      return {...state, echanges_histo: action.payload};

    case FETCH_MESSAGES:
      return {...state, echange: action.payload};

    case ADD_MESSAGE:
      return {...state, newMessage: action.payload, echange: { ...state.echange, solder: null }};

    case CLOTURER_CONVERSATION:
      return {...state, newMessage: {}, echange: { ...state.echange, solder: 'O' }};

    case POST_IMAGE:
      return {...state, newMessage: {...state.newMessage, url: action.payload }};

    case FETCH_MESSAGE:
      return {...state, msg_detail: action.payload };
    default:
      return state;
  }
}
