import React, { Component } from 'react';
import List from './List';
import Detail from './Detail';
import './style.css';

class Dialogues extends Component {
  render () {
    return (
      <div id={"dialogues"} className={"flex-column"}>
        <header>
          Im the header
        </header>
        {/* Container */}

        <div className={"content"}>
          {/* Nav for list */}
          <nav>
            <List />
          </nav>
          {/* Main content */}
          <main>
            <Detail />
          </main>
        </div>
      </div>
    );
  }
}

export default Dialogues;
