import React, { Component } from "react";
import "./factures.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchFactures, markAsDownloaded } from "../../actions/PiecesActions";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import {
  getFrDate,
  updateSizeView,
  numberWithSpaces,
  hexToRgbA,
} from "../../Utils";
import AddConversationButton from "./AddConversationButton";
import PiecesDL from "../PiecesDL/PiecesDL";
import { ListItem } from "../List";
import { pdfjs } from "react-pdf";
import { confs } from "../../conf";
const { PRIMARY_BG } = confs;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

const Facture = ({ fac }) => (
  <article>
    <div className={"facture-card--header"}>
      <div className={"facture-card--numcde"}>
        <h1>Facture n°{fac.numfac}</h1>
      </div>
      <span className={"date"}>
        <span className={"reliquat"}>
          {fac.foua === "A" && (
            <div className={"alert alert-warning"}>Avoir</div>
          )}
        </span>
        Le {getFrDate(fac.date_fac)}
      </span>
    </div>

    <div className={"bandeau-fonctionnel"}>
      <p style={{ marginBottom: ".5rem" }} className={"montant_ht m-0"}>
        {numberWithSpaces(fac.mt_ht)}
        <span className={"devise"}> € H.T</span>
      </p>
      <div className={"tools"}>
        <AddConversationButton echange={fac.echange} />
        <Link
          style={{ textDecoration: "none" }}
          to={fac.numcdc > 0 ? "/commandes/" + fac.hashed_numcdc : "#"}
          onClick={(e) => { markAsDownloaded(fac.piece)(() => {}) }}
        >
          <i className="icon-button fa fa-share" title="Voir la commande" />
        </Link>
      </div>
    </div>
  </article>
);

const Loader = (props) => (
  <div
    style={{ height: "50px" }}
    className={"loader-container " + props.className}
  >
    <LoadingComponent />
  </div>
);

class FacturesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBillIndex: 0,
    };
  }

  async UNSAFE_componentWillMount() {
    /* Attach scroll event */
    const { codcli } = this.props.auth.user;
    const { access_token } = this.props.auth;

    await this.props.fetchFactures(codcli, access_token);
    updateSizeView();
  }


  onClick(index) {
    this.setState({ ...this.state, selectedBillIndex: index });
  }

  render() {
    const { fetching, fac } = this.props;

    return (
      <>
        {fetching && !fac && <Loader className={"mb-3"} />}

        {(!fac || fac.length <= 0) && <div>Pas de factures à télécharger.</div>}
        {fac && fac.length > 0 && (
          <div
            style={{ display: "flex", justifyContent: "space-evenly" }}
            height="100%"
          >
            <div style={{ minWidth: "400px", width: "45%",  marginTop:"30px" }}>
              {
                fac.map((uneFacture, key) => (
                  <ListItem
                    onClick={(e) => this.onClick(key)}
                    key={uneFacture.numfac}
                    id={uneFacture.numfac}
                    style={{
                      backgroundColor:
                        "rgba(" +
                        hexToRgbA(PRIMARY_BG) +
                        ", " +
                        (key % 2 === 0 ? ".6" : ".4") +
                        ")",
                      border: key === this.state.selectedBillIndex ? 'solid red' : 'none'
                    }}
                  >
                    <Facture fac={uneFacture} />
                  </ListItem>
                ))}
            </div>
            <div >
              <div style={{display:"flex", width: "100%", justifyContent:"space-evenly"}}>

              <PiecesDL
                directDL={true}
                documentName={"facture_" + fac[this.state.selectedBillIndex].numfac + ".pdf"}
                confirm={fac[this.state.selectedBillIndex].piece.lu_tie != "O"}
                piece={fac[this.state.selectedBillIndex].piece}
                href={fac[this.state.selectedBillIndex].pdf_file}
                >
                 <i className="fa fa-download icon-button" style={{color: "black"}}>Télécharger</i>
              </PiecesDL>
              <PiecesDL
                print={true}
                confirm={fac[this.state.selectedBillIndex].piece.lu_tie != "O"}
                piece={fac[this.state.selectedBillIndex].piece}
                href={fac[this.state.selectedBillIndex].pdf_file}
              >
                <i className="fa fa-print icon-button" style={{color: "black"}}>Imprimer</i>
              </PiecesDL>
              
                </div>
              <iframe
                id="frame"
                src={fac[this.state.selectedBillIndex].pdf_file + "#toolbar=0"}
                style={{ height: "80vh", width: "45vw" }}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  fac: state.pieces.fac,
  fetching: state.fetching.fetching_fac,
});

export default connect(
  mapStateToProps,
  { fetchFactures }
)(FacturesList);
