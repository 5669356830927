import React, { Component } from 'react';
import LayoutViews from '../components/SwipeNav/LayoutViews';
import NavHeader from '../components/NavHeader/NavHeader';
import MainDrawer, { ToggleMainDrawer } from '../components/DrawersApp/MainDrawer';
import { BonsExpeditionList, BonsExpeditionHisto } from '../components/BonsExpedition';
import Fade from '@material-ui/core/Fade';
import ConfirmModal from '../components/PiecesDL/ConfirmModal';
import { confs } from '../conf';
import { ToggleLegendDrawer, LegendDrawer } from '../components/Legend';

const { PRIMARY_BG } = confs;

const BonsExpeditionHeader = () => (
  <NavHeader title={"Bons d'expédition"}>
    <ToggleMainDrawer />
    <ToggleLegendDrawer />
  </NavHeader>
);

const navs = [
  {title: 'Non facturés', icon: 'file-pdf', animated: true},
  {title: 'Historique', icon: 'hourglass-end', animated: true},
];

const views = [
  BonsExpeditionList,
  BonsExpeditionHisto
];

class BonsExpedition extends Component {
  render() {
    return (
        <Fade in={true} mountOnEnter unmountOnExit>
          <>
            <LayoutViews
              primary={PRIMARY_BG}
              header={BonsExpeditionHeader}
              navs={navs}
              views={views}
              fixedIndex={0}
            />

            <ConfirmModal />
            <LegendDrawer />
            <MainDrawer />
          </>
        </Fade>
    )
  }
}

export default BonsExpedition;
