import React, { Component } from 'react';
import { connect } from 'react-redux';
import ToggleDrawer from '../components/Drawer/ToggleDrawer';
import { ToggleLegendDrawer } from '../components/Legend';
import { ToggleMainDrawer } from '../components/DrawersApp/MainDrawer';
import { confs } from '../conf';
import './pages.css';

const { URL_GED, PRIMARY_BG, SITE_MARCHAND } = confs;

class Header extends Component {
  render() {

    const { nomcli } = this.props.user;
    const style = {
      backgroundImage: 'url('+URL_GED+'/logo-rond.png)'
    };

    return (
      <div className={"header--component"} style={{backgroundColor: PRIMARY_BG}}>

        <a target={"_blank"} href={SITE_MARCHAND}>
          <div id={"logo-marchand"} style={style}>
          </div>
        </a>

        <h1 style={{padding: '0 1rem', flex: '1', textAlign: 'left'}} className={"ellipsis"}>{nomcli}</h1>

        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row-reverse'}}>
          <ToggleMainDrawer />
          <ToggleLegendDrawer/>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user
})

export default connect(mapStateToProps, {})(Header);
