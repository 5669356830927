import React, { Component } from 'react';
import { connect } from 'react-redux';
import { postImage } from './actions';
import Zoom from '@material-ui/core/Zoom';
import { Dialog } from '@material-ui/core';
import { confs } from '../../conf';
import { isMobile } from '../../Utils';
var mime = require('mime-types');

const { PRIMARY_BG } = confs;

function Transition(props) {
  return <Zoom {...props} />;
}

function getCleanFileName(filename) {
  let arr = filename.split('.');
  let newName = "";
  for (var i = 0; i < arr.length-1; i++) {
    newName += arr[i];
  }
  return newName.replace(' ', '_');
}

class PiecesLoader extends Component {

  state = {
    media: this.props.media || {},
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.media && (nextProps.media.url !== this.props.media.url) || !this.state.media) {
      this.setState({
        media: nextProps.media
      });
    }
  }

  handleChange(e) {
    this.setState({ error: null });
    if (!e.target.files.length) {
      return
    }
    this.setState({
      [e.target.name]: e.target.files,
    }, () => {
      var fs = new FileReader();
      fs.onload = (event) => {
        this.img.src = event.target.result;
      }
      fs.readAsDataURL(this.state.file[0]);
      this.setState({ open: true });
      if (this.state.file[0].size > 10000000) {
        this.setState({
          error: "L'image est trop importante. 10Mo maximum."
        });
      }
    });
  }

  postImage() {
    if (this.state.file) {
      const { pathFile, piece } = this.props;
      const params = {
        file: this.state.file,
        pathFile,
        ...piece,
        rename: this.state.rename,
        codope_tie: this.props.codope_tie,
      };
      this.props.postImage(params);
      this.setState({ file: null, open: false, rename: null});
    }
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value.replace(' ', '_')
    });
  }

  closeModal() {
    this.setState({
      open: false,
    }, () => this.setState({ file: null }));
  }

  render() {

    const style = {
      send: {
        width: '100%',
        backgroundColor: PRIMARY_BG,
        color: 'white',
        textAlign: 'center',
        borderRadius: '.3rem',
        marginTop: '.5rem',
        padding: '.5rem',
      },
      cancel: {
        width: '100%',
        backgroundColor: '#b79090',
        color: 'white',
        textAlign: 'center',
        borderRadius: '.3rem',
        marginTop: '.5rem',
        padding: '.5rem',
      }
    }

    const { children, id } = this.props;
    let { url } = this.state.media;
    const { rename } = this.state;

    return (
      <div style={{display: 'none'}}>
        <form>
          <input ref={(input) => this.input=input}
            type="file"
            hidden
            name={"file"}
            id={"input-file--pieces"}
            onChange={(e) => this.handleChange(e)}
          />
        </form>

        <Dialog
           maxWidth={"sm"}
           scroll={"body"}
           open={this.state.open}
           unMountOnExit
           TransitionComponent={Transition}
         >
          <div style={{padding: '.5rem', paddingBottom: '0', maxWidth: '100%', textAlign: 'center'}}>
            <img className={"img-fluid"} ref={(img) => this.img=img} alt="" name={"preview"}/>
          </div>
          {
            this.state.error &&
            <div className={"alert alert-danger"} style={{margin: '.5rem', marginBottom: '0'}}>
              <div className={"alert-body"}>
                {this.state.error}
              </div>
            </div>
          }
          <div style={{padding: '.5rem'}}>
          {
            (this.state.file && this.state.file.length && !this.state.error) &&
              <>
                <div className="input-group">
                  <input className={"form-control"} type="text" placeholder={getCleanFileName(this.state.file[0].name)} value={rename} name={"rename"} onChange={(e) => this.onChange(e)}/>
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">.{mime.extension(this.state.file[0].type)}</span>
                  </div>
                </div>
                <button onClick={() => this.postImage()} style={style.send} disabled={this.state.error}>Envoyer</button>
              </>
          }
            <button onClick={() => this.closeModal()} style={style.cancel}>Annuler</button>
          </div>

         </Dialog>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  codope_tie: state.auth.user.codcli
});

export default connect(mapStateToProps, { postImage })(PiecesLoader);
