import { LOGIN, AUTO_LOGIN } from '../actions/AuthActions';

const initialState = {
  user: {},
  access_token: ''
}

export default function (state=initialState, action) {
  switch (action.type) {
    case LOGIN:
      return action.payload;
    case AUTO_LOGIN:
      return action.payload;

    default:
      return state;
  }
}
