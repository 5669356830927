export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
export const toggleDrawer = (drawer) => ({
  type: TOGGLE_DRAWER, payload: drawer
});

export const OPEN_DRAWER = "OPEN_DRAWER";
export const openDrawer = (drawer) => ({
  type: OPEN_DRAWER, payload: drawer
});

export const CLOSE_DRAWER = "CLOSE_DRAWER";
export const closeDrawer = (drawer) => ({
  type: CLOSE_DRAWER, payload: drawer
});
