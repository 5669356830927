import React, { Component } from 'react';
import './style.css';

class NotificationsZone extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children } = this.props;

    const style = {
      margin: this.props.noMargin? '0': '.5rem'
    }

    return (
      <div className={"notifications-zone"} style={style}>
        { children }
      </div>
    );
  }
}

export default NotificationsZone;
