export const ROOT = '/picourt';

export const HOME =  '/';

export const LOGIN =  '/login';

export const COMMANDES =  '/commandes';

export const PIECES =  '/commandes/pieces/';

export const PIECES_QS =  '/commandes/pieces/:numcdc';

export const FACTURES =  '/factures';

export const ECHANGES =  '/echanges';

export const ECHANGE =  '/echanges/:num_echange';
