import React, { Component } from 'react';
import LayoutViews from '../components/SwipeNav/LayoutViews';
import NavHeader from '../components/NavHeader/NavHeader';
import MainDrawer, { ToggleMainDrawer } from '../components/DrawersApp/MainDrawer';
import { CommandesList, CommandesHisto } from '../components/Commandes';
import Fade from '@material-ui/core/Fade';
import ConfirmModal from '../components/PiecesDL/ConfirmModal';
import { confs } from '../conf';
import { ToggleLegendDrawer, LegendDrawer } from '../components/Legend';
import qs from 'qs';

const { PRIMARY_BG } = confs;

const CommandesHeader = () => (
  <NavHeader title={"Commandes"}>
    <ToggleMainDrawer />
    <ToggleLegendDrawer />
  </NavHeader>
);

const navs = [
  {title: 'En cours', icon: 'calendar-check', animated: true},
  {title: 'Historique', icon: 'hourglass-end', animated: true},
];

const views = [
  CommandesList,
  CommandesHisto,
];

class Commandes extends Component {
  render() {
    return (
        <Fade in={true} mountOnEnter unmountOnExit>
          <>
            <LayoutViews
              primary={PRIMARY_BG}
              header={CommandesHeader}
              navs={navs}
              views={views}
              fixedIndex={0}
            />

            <ConfirmModal />
            <LegendDrawer />
            <MainDrawer />
          </>
        </Fade>
    )
  }
}

export default Commandes;
