import React, { Component } from 'react';
import { connect } from 'react-redux';
import Message from '../Echanges/Message';
import './style.css';


class Detail extends Component {

  render () {
    const { one, fetching, fetched } = this.props.detail;
    return (
      <div className={"messages"}>
        { fetching && <em>Chargement en cours</em>}

        <ul>
          { one && one.messages && one.messages.map((thisOne, key) => (
            <Message key={key} message={thisOne} />
          )) }
        </ul>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  detail: state.dialogues.detail
});

export default connect(mapStateToProps, { })(Detail);
