import {
  FETCH_DIALOGUES,
  FETCH_DIALOGUES_SUCCESS,
  FETCH_DIALOGUES_FAILED,

  FETCH_CONV,
  FETCH_CONV_SUCCESS,
  FETCH_CONV_FAILED,
} from './actions';

const initialState = {
  list: {
    all: [],
    fetching: false,
    fetched: false,
  },
  detail: {
    one: {},
    fetching: false,
    fetched: false
  }
};

export default function (state=initialState, action) {
  switch (action.type) {
    case FETCH_DIALOGUES:
      return {...state, list: {...state.list, fetching: true} };
    case FETCH_DIALOGUES_SUCCESS:
      return {...state, list: {all: action.payload, fetched: true, fetching: false} };
    case FETCH_DIALOGUES_FAILED:
      return initialState;

    case FETCH_CONV:
      return {...state, detail: {...state.one, fetching: true} };
    case FETCH_CONV_SUCCESS:
      return {...state, detail: {one: action.payload, fetched: true, fetching: false} };
    case FETCH_CONV_FAILED:
      return initialState;
    default:
      return state;

  }
}
