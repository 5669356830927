import React, { Component } from 'react';
import RippleEffect from '../Ripple/RippleEffect';
import { connect } from 'react-redux';
import { confs } from '../../conf';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import ATelechargerNotifications from './ATelechargerNotifications';
import MsgNonLuSocNotifications from './MsgNonLuSocNotifications';
import MsgNonLuTieNotifications from './MsgNonLuTieNotifications';

const { URL_API, PRIMARY_BG } = confs;

const style = {
  panel: {
    marginTop: '.5rem',
    marginBottom: '5rem',
  }
};

const Loader = (props) => (
  <div style={{height: '50px'}} className={"loader-container "+props.className}>
    <LoadingComponent />
  </div>
);

const SmallNotifBubble = ({ options }) => {
  const { background } = options;
  return (
    (background) && <span className={"notif small text-white bg-"+background}></span>
  );
};

export const HeaderNotif = ({ children, icon, background }) => (
  <div className={"notification-panel-head pl-3 mb-3"}>
    <h1 style={{color: PRIMARY_BG}}>
      <i className={"fa fa-"+(icon)}></i>
      <SmallNotifBubble options={{background: background}} />
      <span style={{marginLeft: '1.5rem'}}>
        { children }
      </span>
    </h1>
  </div>
);

class NotificationPanel extends Component {

  state = {};

  componentWillMount() {
    const { user, access_token } = this.props.auth;
    fetch(URL_API+'/notifications/main/'+user._id_+'?access_token='+access_token)
      .then(res => res.json()).then(json => this.setState({...json.data}));
  }

  render() {
    const { notifications } = this.state;
    return (
      <div style={style.panel}>
        {!this.state.notifications && <Loader className={"py-5"}/>}

        {
          notifications && (
            <>
              {
                notifications.a_telecharger &&
                  <ATelechargerNotifications aTelecharger={notifications.a_telecharger} />
              }
              {
                notifications.msg_nonlu_tie &&
                  <MsgNonLuTieNotifications msgNonLuTie={notifications.msg_nonlu_tie} />
              }
              {
                notifications.msg_nonlu_soc &&
                  <MsgNonLuSocNotifications msgNonLuSoc={notifications.msg_nonlu_soc} />
              }
            </>
          )
        }

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { })(NotificationPanel);
