import React, { Component } from 'react';
import Fade from '../Transition/Fade';
import Loader from '../Loader/Loader';
import { hexToRgbA } from '../../Utils';
import { confs } from '../../conf';
import './style.css';

const { PRIMARY_BG } = confs;

class LoadingComponent extends Component {
  render() {
    return (
      <Fade id="loading-component">
        <Loader background={hexToRgbA(PRIMARY_BG)}/>
      </Fade>
    );
  }

}

export default LoadingComponent;
