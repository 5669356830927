import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { forgotPassword } from './actions';
import { confs } from '../../conf';

const { PRIMARY_BG, URL_GED } = confs;

class ForgotPasswordForm extends Component {
  state = {
    code: '',
  };

  onChange(e) {
    this.setState({
      code: e.target.value
    });
  }

  render() {
    const { user } = this.props;
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', widht: '100vw', padding: '1rem'}}>
        <div className={"container"}>
          <div className={"row justify-content-center"}>
            <div className={"col-md-6 col-lg-4"}>
              <p style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img src={URL_GED+'/main-logo.jpg'} className={"img-fluid shadow mb-5"} alt=""/>
              </p>

              <label htmlFor="">Identifiant</label>
              <input onChange={(e) => this.onChange(e)} type="text" className={"form-control"}/>

              <button style={{backgroundColor: PRIMARY_BG}} onClick={() => this.props.forgotPassword(this.state)} className={"btn btn-block text-white my-2"}>Demander un nouveau mot de passe</button>
              <Link to={"/login"}>Se connecter</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, { forgotPassword })(ForgotPasswordForm);
