import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleDrawer } from './actions';

class ToggleDrawer extends Component {
  render() {

    const { drawer, icon, label, children } = this.props;


    return (
        <div onClick={() => this.props.toggleDrawer(drawer) } className={(!children && "icon-button")}>
          {
             (!children && icon) && <i className={"fa fa-"+(icon)}></i>
          }
          {
            children && children
          }
        </div>
      );
  }
}

export default connect(null, { toggleDrawer })(ToggleDrawer);
