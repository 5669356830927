import React, { Component } from 'react';
import LoadingAwaitButton from '../LoadingButton/LoadingAwaitButton';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addConversation } from '../../actions/EchangeActions';
import history from '../../history';

const NotifBubble = ({ options }) => {
  const { background, number } = options;
  return (
    (number > 0) && <span className={"notif small text-white bg-"+background}></span>
  );
};

class AddConversationButton extends Component {

  async addConversation(params) {

    if (this.props.echange.num_echange) {
      history.push('/echanges/'+this.props.echange.num_echange);
      return;
    }

    const { access_token } = this.props.auth;

    let res = await this.props.addConversation(params, access_token);

    const { num_echange } = res.payload;
    if(num_echange) {
      history.push('/echanges/'+num_echange);
    }
  }

  render() {

    const { cod_piece, nbr_msg_nonlu_tie, nbr_msg_nonlu_soc, num_echange, solder, typ_piece } = this.props.echange;
    const cod_tiers = this.props.auth.user.codcli;

    const style = {
      color: !num_echange ? 'rgba(255,255,255,.5)' : (solder === 'O') ? 'dark' : 'white'
    };

    const options = {
      background: nbr_msg_nonlu_tie > 0 ? 'success' : 'primary',
      number: nbr_msg_nonlu_tie > 0 ? nbr_msg_nonlu_tie : nbr_msg_nonlu_soc
    }

    return (
        <LoadingAwaitButton  className={"btn btn-tool text-white icon-button"} onClick={
          () => this.addConversation({
            lib_echange: (typ_piece == 'BE'? 'B.E ': (typ_piece==='FAC'? 'Facture ' : 'Commande ' )) + cod_piece,
            cod_tiers: cod_tiers,
            typ_piece: typ_piece? typ_piece:'CDC',
            cod_piece: cod_piece,
            codsit: this.props.auth.user.codsit,
          })
        }>
          <i style={this.props.style} className={"fa fa-comment"}></i>
          {(num_echange && <NotifBubble options={options} />)}
        </LoadingAwaitButton>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps, { addConversation })(AddConversationButton));
