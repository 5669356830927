import React, { Component } from 'react';
import AddFileDrawer, { ToggleAddFileDrawer } from '../DrawersApp/AddFileDrawer';
import { confs } from '../../conf';

const { PRIMARY_BG } = confs;

const style = {
  button: {
    color: PRIMARY_BG,
  },
}

class ConversationFileAdder extends Component {

  openSelectFile() {
    var input = document.getElementById('input-file--pieces');
    if (input) {
      input.click();
    }
  }

  render() {
    return (
        <div onClick={() => this.openSelectFile()} className={"icon-button mr-2"} style={style.button}>
          <i className={"fa fa-camera text-white"}></i>
        </div>
    );
  }
}

export default ConversationFileAdder;
