import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AddConversationButton from './AddConversationButton';
import SuiviColisModal from './SuiviColisModal';
import { hexToRgbA, getFrDate } from '../../Utils';
import { confs } from '../../conf';
import qs from 'qs';

const { PRIMARY_BG } = confs;

const style = {
  card: {
    display: 'flex',
    alignItems: 'center',
    padding: '.5rem',
    justifyContent: 'space-between'
  },
  content: {
    flex: '1',
  },
  h1: {
    fontSize: '24px',
    color: PRIMARY_BG,
    paddingLeft: '.5rem',
    borderLeft: '3px solid '+PRIMARY_BG,
    minHeight: '40px',
  },
  logo: {
    color: 'rgba('+hexToRgbA(PRIMARY_BG)+', .5)',
    marginRight: '1rem',
    fontSize: '3rem',
  },
  title: {
    color: PRIMARY_BG,
    fontWeight: 'bold',
    fontSize: '18px',
    position: 'relative',
  },
  tools: {
    display: 'flex',
  }
};

const PieceCard = ({ piece, selected }) => (
    <div className={'ripple'} style={{...style.card, border: selected ? 'solid red' : 'none'}}>

      <div style={style.logo}>
        <i className={"fa fa-file-pdf"}></i>
      </div>
      <div style={style.content} className={"ml-2"}>
        <div style={style.title}>
          {piece.numbe}
        </div>
        <div>
          <small>{getFrDate(piece.date_bl)}</small>
        </div>
      </div>

      <div style={style.tools}>

        {
          (piece.colis && piece.colis.length > 0) &&
            <SuiviColisModal primary colis={piece.colis} />
        }

        <AddConversationButton style={{color: PRIMARY_BG}} piece={piece.piece} echange={piece.echange} />

      </div>

    </div>
);

class PiecesList extends Component {

  componentDidMount() {
    const params = qs.parse(this.props.location.search.replace('?', '')) || 0;
    let el = document.getElementById(params.show);
    if (el) {
      let top = el.getBoundingClientRect();
      window.scrollTo(0, top.y - 100);
    }
  }

  render() {
    const { pieces, onClickCallback, selectedDocName, selectedDocNum } = this.props;
    const params = qs.parse(this.props.location.search.replace('?', '')) || 0;
    return (
      <div className={"container"}>
        <div className={"row justify-content-center"}>
          <div className={"col-md-12"}>
            <h1 style={style.h1}>Bons d'expédition</h1>
            {
              pieces && pieces.map((piece, key) => (
                <div key={key} id={piece.numbe} className={(params.show == piece.numbe && params.typ === 'BE') ? 'show-ilsduc': ''} onClick={(e) => onClickCallback(piece, "bon_expedition", piece.numbe)} >
                  <PieceCard piece={piece} selected={selectedDocName === "bon_expedition" && selectedDocNum === piece.numbe} />
                </div>
              ))
            }
            {
              pieces && pieces.length <= 0 &&
                <div className={"ml-3"}> Pas de bon d'expédition</div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PiecesList);
