import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { sendMessage, postImage } from '../../actions/EchangeActions';
import { isMobile } from '../../Utils';
import history from '../../history';
import LoadingAwaitButton from '../LoadingButton/LoadingAwaitButton';
import ConversationFileAdder from './ConversationFileAdder';
import './echanges.css';
import { confs } from '../../conf';

const { PRIMARY_BG } = confs;

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  componentDidMount() {
    let el = document.getElementById('conversation-box');
    // this.textarea.focus(() => (
      el.scrollTo({
        top: 10000000000,
        behavior: 'smooth'
      });
    // ));
  }

  async sendMessage() {
    if (!this.state.msg_contenu) { return; }
    const params = {
      ...this.state,
      codope_tiers: this.props.user.codcli,
      codsit: this.props.user.codsit,
      num_echange: this.props.match.params.num_echange
    }
    const { access_token } = this.props.auth;
    await this.props.sendMessage(params, access_token);

    this.setState({...this.state, msg_contenu: ''});

    let el = document.getElementById('conversation-box');
    if (el) {
      el.scrollTo({
        top: 10000000000,
        behavior: 'smooth'
      });
    }
  }

  giveFocus() {
    let el = document.getElementById('conversation-box');
    if (this.textarea) {
      this.textarea.focus(() => (
        el.scrollTo({
          top: el.getBoundingClientRect().height+1000,
          behavior: 'smooth'
        })
      ));
    }
    this.setState({...this.state, focused: true});
  }

  render() {

    const { message, echange } = this.props;

    return (
        <>
        {
          (this.state.focused && isMobile()) &&
            <small style={{backgroundColor: PRIMARY_BG, color: 'white', padding: '.5rem', paddingBottom: '0'}}>
              {echange && echange.lib_echange}
            </small>
        }

        <section id={"message-form"} style={{backgroundColor: PRIMARY_BG}}>
          <div id={"message-form--overlay"}></div>
          {/* ididididid le 10/07/20919 */}
          {/*<ConversationFileAdder />*/}
          <textarea
            contentEditable={true}
            onFocus={() => this.giveFocus()}
            onBlur={() => this.setState({...this.state, focused: false})}
            ref={(textarea => this.textarea = textarea)}
            name={"msg_contenu"}
            onChange={(e) => this.handleChange(e)}
            rows={"1"} type={"text"}
            placeholder={"Votre message"}
            value={this.state.msg_contenu}
          />
          <LoadingAwaitButton className={"text-white ml-1"} onClick={()=>this.sendMessage()}>
            <i className={"fa fa-paper-plane"} style={{padding: '.5rem'}}></i>
          </LoadingAwaitButton>
        </section>
        </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps, { sendMessage, postImage })(Message));
