import React, { Component } from 'react';
import NavViews from './NavViews';
import Views from './Views';
import './style.css';

class LayoutViews extends Component {
  render() {

    const { header, navs, views } = this.props;
    const Header = header;

    return (
      <div className={"layout-views--c"}>
        {/* include an header if is set */}
        {header && <Header {...header.props} />}
        {/* include navigation on top if is set */}
        {(navs && !this.props.hasOwnProperty("bottomNavigation")) && <NavViews navs={navs} />}
        {/* include views */}
        {views && <Views views={views} />}
        {/* include navigation on bottom if is set */}
        {(navs && this.props.hasOwnProperty("bottomNavigation")) && <NavViews navs={navs} />}
      </div>
    );
  }
}

export default LayoutViews;
