import React from 'react';

const withLocalStorage = (name) => WrappedComponent => props => {
  class HOC extends React.Component {
    render() {
      return <WrappedComponent {...props} />;
    }
  }

  return HOC;
};

export {withLocalStorage};
