import React, { Component } from 'react';
import './style.css';

class Fade extends Component {

  render() {
    return (
      <div {...this.props} className="fade-component">
        {this.props.children}
      </div>
    );
  }

}

export default Fade;
