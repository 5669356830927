import { FETCHING_USER } from '../actions/AuthActions';
import { FETCHING_CDE, FETCHING_FAC, FETCHING_BES } from '../actions/PiecesActions';
import { FETCHING_ECHANGE, FETCHING_MESSAGES, POSTING_IMAGE } from '../actions/EchangeActions';

const initialState = {
  fetching_user: false,
  fetching_cde: false,
  fetching_bes: false,
  fetching_fac: false,
  fetching_echanges: false,
  fetching_messages: false,
};

export default function(state=initialState, action) {
  switch (action.type) {

    case FETCHING_USER:
      return {...state, fetching_user: action.payload};

    case FETCHING_CDE:
      return {...state, fetching_cde: action.payload};

    case FETCHING_BES:
      return {...state, fetching_bes: action.payload};

    case FETCHING_FAC:
      return {...state, fetching_cde: action.payload};

    case FETCHING_ECHANGE:
      return {...state, fetching_echanges: action.payload};

    case FETCHING_MESSAGES:
      return {...state, fetching_messages: action.payload};

    case POSTING_IMAGE:
      return {...state, posting_image: action.payload};

    default:
      return state;
  }
}
