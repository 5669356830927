import React, { Component } from 'react';
import NavHeader from '../components/NavHeader/NavHeader';
import Fade from '@material-ui/core/Fade';
import ForgotPasswordForm from '../components/Account/ForgotPasswordForm';

const ForgotPasswordHeader = () => (
  <NavHeader title={"Mot de passe oublié"}></NavHeader>
);

class ForgotPasswordPage extends Component {
  render() {
    const { user } = this.props;
    return (
        <Fade in={true} mountOnEnter unmountOnExit>
          <>

            <ForgotPasswordForm />

          </>
        </Fade>
    )
  }
}

export default ForgotPasswordPage;
