import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchCDESHisto } from '../../actions/PiecesActions';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import {getFrDate, numberWithSpaces, hexToRgbA} from '../../Utils';
import AddConversationButton from './AddConversationButton';
import PiecesDL from '../PiecesDL/PiecesDL';
import { Accordion } from '../Accordion';
import List from '../List/List';
import ListItem from '../List/ListItem';
import {confs} from '../../conf';
const { PRIMARY_BG } = confs;


const Commande = ({ cdc, month, date_demarrage }) => (
  <article>

    <div className={"facture-card--header"}>
      <div className={"facture-card--numcde"}>
        <h1>
          Commande n°{cdc.numcdc}
        </h1>
      </div>
      <span className={"date"}>

        <span className={"reliquat"}></span>

        <span style={{color: 'red', padding: '0 1rem'}}>
          {month}
        </span>
        Le {getFrDate(cdc.date)}
      </span>
    </div>

    <div>
      <em>
        {cdc.ref_cli}
      </em>
    </div>

    

    <div className={"bandeau-fonctionnel"}>
      <div style={{marginBottom: '.5rem'}}>
        <div style={{margin: '0'}} className={"montant_ht"}>
          {numberWithSpaces(cdc.montant_ht)}<span className={"devise"}> € H.T</span>
        </div>
      </div>
      {
          <div className={"tools"}>
            <AddConversationButton echange={cdc.echange} />
            {
              <Link to={"commandes/"+(cdc._id_)} className={"icon-button"}>
                <i className={"fa fa-share btn btn-tool text-white"}></i>
              </Link>
            }
          </div>
      }

    </div>

    <div className={"facture-card--content"}>
    </div>
  </article>
);

const Loader = (props) => (
  <div style={{height: '50px'}} className={"loader-container "+props.className}>
    <LoadingComponent />
  </div>
);

class CommandesHisto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      selectedCmdIndex: 0,
      selectedYearIndex: 0,
    };
  }

  onClick(yearIndex, cmdIndex) {
    this.setState({ ...this.state, selectedCmdIndex: cmdIndex, selectedYearIndex: yearIndex });
  }

  async UNSAFE_componentWillMount() {
    const { _id_ } = this.props.auth.user;
    const params = {
      access_token: this.props.auth.access_token,
    };

    let res = await this.props.fetchCDESHisto(_id_, params);
  }

  async loadMore() {
    await this.props.fetchCDESHisto(
      this.props.auth.user._id_, {
        access_token: this.props.auth.access_token,
        all: true
      }
    );
    this.setState({isLoaded: true});
  }

  render() {
    const { fetching, cdes_histo } = this.props;
    const months = ["JAN", "FEV", "MAR", "AVR", "MAI", "JUIN", "JUI", "AOU", "SEP", "OCT", "NOV", "DEC"];
    let ruptureMois = 0;

    if ((this.props.active !== 1) && !cdes_histo) { return null }

    // updateSizeView();

    return (

      <>
        <div
          className={"container"}
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-evenly",
            margin: 0,
            padding: 0,
            maxWidth: "100%",
            minWidth: "100%"
          }}
        >
          {
            (fetching) && <Loader className={"mb-3"} />
          }
          {
            (!fetching && cdes_histo) && 
              <div style={{minWidth: "400px", width: "45%"}}>
                {
                  cdes_histo.map((year, yearIndex) => (
                    <div key={yearIndex}>
                      <div>
                        <Accordion label={year.year} defaultIsOpen={yearIndex === 0}>
                          <List>
                          {
                            year.commandes.map((commande, cmdIndex)=>{
                              let date = new Date(commande.date);
                              if (cmdIndex === 0) { ruptureMois = date.getMonth() }
                              if (ruptureMois !== date.getMonth()){
                                ruptureMois = date.getMonth();
                                return (
                                  <>
                                    <div className={"separator"}></div>
                                    <ListItem 
                                      key={yearIndex.toString() + cmdIndex.toString()} 
                                      onClick={(e) => this.onClick(yearIndex, cmdIndex)}
                                      style={{
                                        backgroundColor: 
                                          'rgba(' + 
                                          hexToRgbA(PRIMARY_BG)+
                                          ', '+ 
                                          (cmdIndex%2===0?'.6':'.4') +
                                          ')',
                                          border: cmdIndex === this.state.selectedCmdIndex && yearIndex === this.state.selectedYearIndex ? 'solid red' : 'none'
                                      }}
                                    >
                                      <Commande month={months[date.getMonth()]} date_demarrage={this.props.auth.user.web_date_mail} cdc={commande} />
                                    </ListItem>
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    <ListItem 
                                      key={yearIndex.toString() + cmdIndex.toString()} 
                                      onClick={(e) => this.onClick(yearIndex, cmdIndex)} 
                                      style={{
                                        backgroundColor:
                                          'rgba(' +
                                          hexToRgbA(PRIMARY_BG) +
                                          ', ' +
                                          (cmdIndex%2===0?'.6':'.4') +
                                          ')',
                                          border: cmdIndex === this.state.selectedCmdIndex && yearIndex === this.state.selectedYearIndex ? 'solid red' : 'none'
                                      }}
                                    >
                                      <Commande month={months[date.getMonth()]} cdc={commande} />
                                    </ListItem>
                                  </>
                                );
                              }
                            })
                          }
                          </List>
                        </Accordion>
                      </div>
                    </div>
                  ))
                }
              </div>
          }
          {
            (!fetching && cdes_histo && cdes_histo[this.state.selectedYearIndex]) && (
              <div>
                <div style={{display:"flex", width: "100%", justifyContent:"space-evenly"}}>
                  <PiecesDL
                    directDL={true}
                    documentName={"commande_" + cdes_histo[this.state.selectedYearIndex].commandes[this.state.selectedCmdIndex].numcdc + ".pdf"}
                    confirm={false}
                    save={cdes_histo[this.state.selectedYearIndex].commandes[this.state.selectedCmdIndex].piece.lu_tie != 'O'}
                    piece={cdes_histo[this.state.selectedYearIndex].commandes[this.state.selectedCmdIndex].piece}
                    href={cdes_histo[this.state.selectedYearIndex].commandes[this.state.selectedCmdIndex].pdf_file}
                    >
                     <i className="fa fa-download icon-button" style={{color: "black"}}>Télécharger</i>
                  </PiecesDL>
                  <PiecesDL
                    print={true}
                    confirm={false}
                    save={cdes_histo[this.state.selectedYearIndex].commandes[this.state.selectedCmdIndex].piece.lu_tie != 'O'}
                    piece={cdes_histo[this.state.selectedYearIndex].commandes[this.state.selectedCmdIndex].piece}
                    href={cdes_histo[this.state.selectedYearIndex].commandes[this.state.selectedCmdIndex].pdf_file}
                  >
                    <i className="fa fa-print icon-button" style={{color: "black"}}>Imprimer</i>
                  </PiecesDL>
                </div>
                <iframe
                  title="frame"
                  id="frame"
                  src={cdes_histo[this.state.selectedYearIndex].commandes[this.state.selectedCmdIndex].pdf_file + "#toolbar=0"}
                  style={{ height: "80vh", width: "45vw" }}
                />
              </div>
            )
          }
          {
            (!fetching && cdes_histo.length <= 0) &&
              <div style={{padding: '1rem'}}>Pas de commandes en historique</div>
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  cdes_histo: state.pieces.cdes_histo,
  fetching: state.fetching.fetching_cde,
  active: state.swipeViews.active
});

export default connect(mapStateToProps, { fetchCDESHisto })(CommandesHisto);
