import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import './style.css';
import { confs } from '../../conf';
import RippleEffect from '../Ripple/RippleEffect';

let { URL_API, PRIMARY_BG } = confs;

const DesktopIconNotification = (props) => {
  const { background, number } = props;
  return (
    <span className={"notif bg-"+background}>{number}</span>
  );
}

class DesktopIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    let codcli = this.props.auth.user._id_;
    let { access_token } = this.props.auth;

    fetch(URL_API+'/notifications-'+ this.props.notifs_src +'/'+codcli+'?access_token='+access_token)
    .then(res => res.json())
    .then(data => this.setState({notifs: data.data}));
  }

  render() {

    const { path, label, img, letter } = this.props;
    const { url } = this.props.match;
    const { notifs } = this.state;

    return (
      <RippleEffect>
      <div className={"desktop-icon"}>
        <Link to={path}>
          <div
            className={"desktop-icon--img"}
            style={{
                backgroundImage: 'url('+img+')',
                color: PRIMARY_BG,
                background: 'white',
                border: '1px solid '+PRIMARY_BG,
                position: 'relative',
              }}
            >
            {!img && letter }
            <div className={"notification-panel m-0"}>
            {((notifs && notifs.a_telecharger > 0) && <DesktopIconNotification background={"danger"} number={notifs.a_telecharger} />)}
            {((notifs && notifs.msg_nonlu > 0) && <DesktopIconNotification background={"success"} number={notifs.msg_nonlu} />)}
            </div>
          </div>
          {(label)}
          {((notifs && notifs.en_cours > 0) && " (" + notifs.en_cours + ")")}
        </Link>
      </div>
      </RippleEffect>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps, {})(DesktopIcon));
