import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeAccess } from './actions';
import { confs } from '../../conf';
import LoadingAwaitButton from '../LoadingButton/LoadingAwaitButton';
import Slide from '@material-ui/core/Slide';
import { Dialog } from '@material-ui/core';
import RippleEffect from '../Ripple/RippleEffect';

function Transition(props) {
  return <Slide direction={"up"} {...props} />;
}

const { PRIMARY_BG } = confs;

const style = {
  title: {
    fontSize: '1.2rem',
    color: PRIMARY_BG,
    marginBottom: '1rem',
  },
};

class DesactivateAccount extends Component {
  state = {
    open: false,
  };

  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
  }

  onEnd() {
    localStorage.clear();
    window.location.href = '/login';
  }

  render() {
    const { account } = this.props;

    return (
        <>
          <div style={style.title}>
            Désactiver mon compte
          </div>
          <button onClick={() => this.openModal()} className={"btn mb-2 btn-block text-white"} style={{backgroundColor: PRIMARY_BG}}>
            Me désinscrire de ce service
          </button>
          <Dialog
             open={this.state.open}
             onClose={() => this.closeModal()}
             unMountOnExit
             TransitionComponent={Transition}
           >
            <div style={{padding: '1rem'}}>
              Etes-vous certain de vouloir désactiver ce profil?
              <p>
                <small>(Il vous sera impossible de réactiver votre compte sans passer par un administrateur.)</small>
              </p>
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', padding: '1rem'}}>
              <RippleEffect>
                <LoadingAwaitButton onClick={() => this.props.changeAccess({access_ok: false, code: account})} onEnd={() => this.onEnd()} className={"btn btn-link"}>Oui</LoadingAwaitButton>
              </RippleEffect>
              <RippleEffect>
                <button onClick={() => this.closeModal()} className={"btn btn-link"}>Non</button>
              </RippleEffect>
             </div>
           </Dialog>
        </>
    );
  }
}

export default connect(null, { changeAccess })(DesactivateAccount);
