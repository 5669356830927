import React, { Component } from 'react';

class NavItem extends Component {
  render() {

    const { icon, title, animated, onClick, active } = this.props;


    return (
      <div
        className={"nav-item--c"+(active?' active':'')}
        onClick={(index) => onClick(index)}
      >
        {icon && <i className={("fa fa-"+icon)+(animated?' animated':'')}></i>}
        {title && title}
      </div>
    );
  }
}

export default NavItem;
