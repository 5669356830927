import { call, put, takeLatest } from 'redux-saga/effects';
import { account } from './api';

export function* changePassword(action) {
  try {
    const accountChanged = yield call(account.changePassword, action.payload);
    yield put({ type: 'HIDE_SNACKBAR'});
    yield put({ type: 'SHOW_SNACKBAR', payload: {
        label: 'Mot de passe réinitialisé avec succès!',
        type: 'success',
      }
    });
  } catch (e) {
    yield put({ type: 'HIDE_SNACKBAR'});
    yield put({ type: 'SHOW_SNACKBAR', payload: {
        label: e.response.data,
        type: 'error',
      }
    });
  }
}

export function* changeAccess(action) {
  try {
    const accountChanged = yield call(account.changeAccess, action.payload);
    yield put({ type: 'HIDE_SNACKBAR'});
    yield put({ type: 'SHOW_SNACKBAR', payload: {
        label: 'Votre compte est désactivé.',
        type: 'success',
      }
    });
  } catch (e) {
    yield put({ type: 'HIDE_SNACKBAR'});
    yield put({ type: 'SHOW_SNACKBAR', payload: {
        label: e.response.data,
        type: 'error',
      }
    });
  }
}

export function* forgotPassword(action) {
  try {
    const accountChanged = yield call(account.forgotPassword, action.payload);
    yield put({ type: 'HIDE_SNACKBAR'});
    yield put({ type: 'SHOW_SNACKBAR', payload: {
        label: 'Un nouveau mot de passe vous sera envoyé par mail sous 24h.',
        type: 'success',
      }
    });
    window.location.href = '/login';
  } catch (e) {
    yield put({ type: 'HIDE_SNACKBAR'});
    yield put({ type: 'SHOW_SNACKBAR', payload: {
        label: e.response.data,
        type: 'error',
      }
    });
  }
}

function* accountSaga() {
  yield takeLatest("RESET_PASSWORD", changePassword);
  yield takeLatest("CHANGE_ACCESS", changeAccess);
  yield takeLatest("FORGOT_PASSWORD", forgotPassword);
}

export default accountSaga;
