export const RESET_PASSWORD = 'RESET_PASSWORD';
export const resetPassword = (password) => ({
  type: RESET_PASSWORD, payload: password
});

export const CHANGE_ACCESS = 'CHANGE_ACCESS';
export const changeAccess = (access_ok) => ({
  type: CHANGE_ACCESS, payload: access_ok
});

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const forgotPassword = (code) => ({
  type: FORGOT_PASSWORD, payload: code
});
