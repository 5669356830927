import axios from 'axios';
import qs from 'qs';
import { confs } from '../conf';

const { URL_API } = confs;

export const LOGIN = 'LOGIN';
export const AUTO_LOGIN = 'AUTO_LOGINLOGIN';
export const FETCHING_USER = 'FETCHING_USER';


export const login = (user) => (dispatch) => {
  dispatch({type: FETCHING_USER, payload: true});

  return axios({
    method: 'post',
    url: URL_API+"/login",
    data: qs.stringify(user)
  })
  .then(res => {

    if(res.status === 200){
      return dispatch({type: FETCHING_USER, payload: false}, dispatch({
        type: LOGIN,
        payload: res.data.data,
      }));
    }

   }).catch(err => {

      return dispatch({type: FETCHING_USER, payload: null}, dispatch({type:LOGIN, payload: {user:null, access_token: null}}));

   });
}

export const autoLogin = (user) => (dispatch) => {
  dispatch({type: FETCHING_USER, payload: true});

  return axios({
    method: 'post',
    url: URL_API+"/auto-login",
    data: qs.stringify(user)
  })
  .then(res => {

    if(res.status === 200){
      return dispatch({type: FETCHING_USER, payload: false}, dispatch({
        type: LOGIN,
        payload: res.data.data,
      }));
    }

   }).catch(err => {

      return dispatch({type: FETCHING_USER, payload: null}, dispatch({type:LOGIN, payload: {user:null, access_token: null}}));

   });
}

export const logout = (access_token) => dispatch => {
  const params = { access_token: access_token };
  return axios({
    method: 'POST',
    url: URL_API+"/logout",
    data: qs.stringify(params)
  })
  .then(res => {

    if(res.status === 200){
      return dispatch({type: FETCHING_USER, payload: false}, dispatch({
        type: LOGIN,
        payload: {},
      }));
    }

  }).catch(err => {

    return dispatch({type: FETCHING_USER, payload: null}, dispatch({type:LOGIN, payload: {user:null, access_token: null}}));

  });
}
