import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleModal } from './ConfirmModal';
import { markAsDownloaded } from '../../actions/PiecesActions';

class PiecesDL extends Component {

  constructor(props) {
    super(props);
  }

  handleClick(e) {
    e.preventDefault();
    let { href, piece } = this.props;
    if (this.props.directDL) {
      this.onDirectDownload();
    }
    else if (this.props.print) {
      this.onPrintFromIFrame() 
    }
    else {
      window.open(href, '_blank');
    }
    if (this.props.confirm) {
      this.props.toggleModal(piece);
    } else {
      if (this.props.save) {
        let { auth } = this.props;
        piece = {...piece, codsit: this.props.auth.user.codsit, codcli: auth.user.codcli};
        this.props.markAsDownloaded(piece);
        window.location.reload();
      }
    }
  }

  onPrintFromIFrame() {
    const frame = document.getElementById("frame");
    frame.contentWindow.focus();
    frame.contentWindow.print();
  }

  onDirectDownload() {
    const url = this.props.href;
    const fileName = this.props.documentName;
    //Create XMLHTTP Request.
    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function() {
      //Convert the Byte Data to BLOB object.
      const blob = new Blob([req.response], {
        type: "application/octetstream",
      });

      //Check the Browser type and download the File.
      const isIE = false || !!document.documentMode;
      if (isIE) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        const url = window.URL || window.webkitURL;
        const link = url.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("download", fileName);
        a.setAttribute("href", link);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  }


  
  render() {

    const { children, className } = this.props;

    return (
      <div>
        {
          children &&
          <div className={className} onClick={(e) => this.handleClick(e)}>{ children }</div>
        }
        {
          !children &&
          <a className={className} href="#" onClick={(e) => this.handleClick(e)}> Télécharger </a>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { toggleModal, markAsDownloaded })(PiecesDL);
