import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../actions/AuthActions';
import Drawer from '../Drawer/Drawer';
import ToggleDrawer from '../Drawer/ToggleDrawer';

function openSelectFile() {
  var input = document.getElementById('input-file--pieces');
  if (input) {
    input.click();
  }
}

class AddFileDrawer extends Component {
  render() {
    const items = [
      {title: 'Choisir un fichier', icon: 'folder', onClick: () => openSelectFile()},
    ];

    const { children } = this.props;

    return (
      <Drawer
        position={"bottom"}
        drawer={"add-file-drawer"}
        items={items}
      >
      </Drawer>
    );
  }
}

export const ToggleAddFileDrawer = ({ children }) => (
  <ToggleDrawer drawer={"add-file-drawer"}>
    { children }
  </ToggleDrawer>
);

export default connect(null, { logout })(AddFileDrawer);
