import axios from 'axios';
import qs from 'qs';
import { confs } from '../conf';

const { URL_API } = confs;

export const FETCH_ECHANGE_ENCOURS = 'FETCH_ECHANGE_ENCOURS';
export const FETCH_ECHANGE_HISTO = 'FETCH_ECHANGE_HISTO';
export const FETCHING_ECHANGE = 'FETCHING_ECHANGE';

export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const FETCHING_MESSAGES = 'FETCHING_MESSAGES';

export const FETCH_MESSAGE = 'FETCH_MESSAGE';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const POSTING_IMAGE = 'POSTING_IMAGE';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const ADD_CONVERSATION = 'ADD_CONVERSATION';
export const CLOTURER_CONVERSATION = 'CLOTURER_CONVERSATION';

export const POST_IMAGE = 'POST_IMAGE';
export const POST_IMAGE_MESSAGE = 'POST_IMAGE_MESSAGE';

export const fetchEchangesEnCours = (codcli, access_token) => dispatch => {
  const params = {access_token: access_token};

  dispatch({type: FETCHING_ECHANGE, payload: true});

  return axios.get(URL_API+'/echanges-encours/'+codcli, {params})
  .then(res => {

    if(res.status === 200){
      return dispatch({type: FETCHING_ECHANGE, payload: false}, dispatch({
        type: FETCH_ECHANGE_ENCOURS,
        payload: res.data.data,
      }));
    }

 }).catch(err => {

    return dispatch({type: FETCHING_ECHANGE, payload: null}, dispatch({type:FETCH_ECHANGE_ENCOURS, payload: null}));

 });

};

export const fetchEchangesHisto = (codcli, access_token) => dispatch => {
  const params = {access_token: access_token};

  dispatch({type: FETCHING_ECHANGE, payload: true});

  return axios.get(URL_API+'/echanges-historique/'+codcli, {params})
  .then(res => {

    if(res.status === 200){
      return dispatch({type: FETCHING_ECHANGE, payload: false}, dispatch({
        type: FETCH_ECHANGE_HISTO,
        payload: res.data.data,
      }));
    }

 }).catch(err => {

    return dispatch({type: FETCHING_ECHANGE, payload: null}, dispatch({type:FETCH_ECHANGE_HISTO, payload: null}));

 });

};

export const fetchMessages = (num_echange, access_token) => dispatch => {
  const params = {access_token: access_token};

  dispatch({type: FETCHING_MESSAGES, payload: true});

  return axios.get(URL_API+'/messages/'+num_echange, {params})
  .then(res => {

    if(res.status === 200){
      return dispatch({type: FETCHING_MESSAGES, payload: false}, dispatch({
        type: FETCH_MESSAGES,
          payload: res.data.data===false? [] : res.data.data,
      }));
    }

 }).catch(err => {

    return dispatch({type: FETCHING_MESSAGES, payload: null}, dispatch({type:FETCH_MESSAGES, payload: null}));

 });
};

export const fetchMessageDetail = (row_idfixe, access_token) => dispatch => {
  const params = {access_token: access_token};

  dispatch({type: FETCHING_MESSAGES, payload: true});

  return axios.get(URL_API+'/message-detail/'+row_idfixe, {params})
  .then(res => {

    if(res.status === 200){
      return dispatch({type: FETCHING_MESSAGES, payload: false}, dispatch({
        type: FETCH_MESSAGE,
        payload: res.data.data,
      }));
    }

 }).catch(err => {

    return dispatch({type: FETCHING_MESSAGES, payload: null}, dispatch({type:FETCH_MESSAGE, payload: null}));

 });

};


export const sendMessage = (message, access_token) => (dispatch) => {

  return axios({
    method: 'post',
    url: URL_API+"/messages",
    data: qs.stringify(message)+qs.stringify(access_token)
  })
  .then(res => {
    if(res.status === 200){
      return dispatch({
        type: ADD_MESSAGE,
        payload: res.data.data,
      });
    }

   }).catch(err => {

      return dispatch({ type:ADD_MESSAGE, payload: {} });

   });
}
export const updateMessage = (message, access_token) => (dispatch) => {

  return axios({
    method: 'post',
    url: URL_API+"/message-update",
    data: qs.stringify(message)+qs.stringify(access_token)
  })
  .then(res => {
    if(res.status === 200){
      return dispatch({
        type: UPDATE_MESSAGE,
        payload: res.data.data,
      });
    }

   }).catch(err => {

      return dispatch({ type:UPDATE_MESSAGE, payload: {} });

   });
}

export const addConversation = (params, access_token) => (dispatch) => {

  return axios({
    method: 'post',
    url: URL_API+"/conversation",
    data: qs.stringify(params)+qs.stringify(access_token)
  })
  .then(res => {
    if(res.status === 200){
      return dispatch({
        type: ADD_CONVERSATION,
        payload: res.data.data,
      });
    }

   }).catch(err => {

      return dispatch({ type:ADD_CONVERSATION, payload: {} });

   });
}

export const cloturerEchange = (params, access_token) => (dispatch) => {
  return axios({
    method: 'POST',
    url: URL_API+"/conversation-cloture",
    data: qs.stringify(params)
  })
  .then(res => {
    if(res.status === 200){
      return dispatch({
        type: CLOTURER_CONVERSATION,
        payload: res.data.data,
      });
    }

   }).catch(err => {

      return dispatch({ type:CLOTURER_CONVERSATION, payload: {} });

   });
}


export const postImage = (params, access_token) => (dispatch) => {
  return axios({
    method: 'POST',
    url: URL_API+"/images/upload",
    data: qs.stringify(params)
  })
  .then(res => {
    if(res.status === 200){
      return dispatch({
        type: POST_IMAGE,
        payload: res.data.data,
      });
    }

   }).catch(err => {

      return dispatch({ type:POST_IMAGE, payload: {} });

   });
}


export const postImageMessage = (params, access_token) => (dispatch) => {
  var formData = new FormData();
  formData.append("file", params.file[0]);
  formData.append("pathFile", params.pathFile);
  formData.append("codope_tiers", params.codope_tiers);
  formData.append("num_echange", params.num_echange);
  formData.append("rename", params.rename?params.rename:'');

  dispatch({type: POSTING_IMAGE, payload: true});

  return axios({
    method: 'POST',
    url: URL_API+"/echanges/upload?access_token="+access_token,
    data: formData
  })
  .then(res => {
    if(res.status === 200){
      return dispatch({type: POSTING_IMAGE, payload: false}, dispatch({
        type: ADD_MESSAGE,
        payload: res.data.data,
      }));
    }

   }).catch(err => {

      return dispatch({ type:ADD_MESSAGE, payload: {} });

   });
}
