import React, { Component } from 'react';
import NavHeader from '../components/NavHeader/NavHeader';
import Fade from '@material-ui/core/Fade';
import Account from '../components/Account/Account';
import { ToggleLegendDrawer, LegendDrawer } from '../components/Legend';

const AccountHeader = () => (
  <NavHeader title={"Mon profil"}>
    <ToggleLegendDrawer />
  </NavHeader>
);


class AccountPage extends Component {
  render() {
    const { user } = this.props;
    return (
        <Fade in={true} mountOnEnter unmountOnExit>
          <>
            <AccountHeader />

            <Account />

            <LegendDrawer />
          </>
        </Fade>
    )
  }
}

export default AccountPage;
