import React, { Component } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setIndex } from './actions';
import qs from 'qs';

const style = {
  container: {
    height: '100%',
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch',
  },
  slide: {
    padding: 15,
    minHeight: 100,
    color: '#fff',
  }
}

class Views extends Component {

  componentDidMount() {
    // this.attacheEvent();
    const { swiper_index } = qs.parse(this.props.location.search);
    
    if (swiper_index && this.props.views) {
      
      if (swiper_index >= 0 && swiper_index <= this.props.views.length) {
        console.log(swiper_index >= 0 && swiper_index <= this.props.views.length);
        this.props.setIndex(parseInt(swiper_index));
      }
    }
  }

  // attacheEvent() {
  //   let el = document.querySelector('.views--c-slides');
  //   for (var i = 0; i < el.length; i++) {
  //   }
  //   el.addEventListener('scroll', this.handleScroll.bind(this));
  // }

  // handleScroll(e) {
  // }

  render() {

    const { views, index, setIndex } = this.props;

    return (
      <SwipeableViews style={style.container} onChangeIndex={(newIndex) => setIndex(newIndex)} index={index} slideClassName={"views--c-slides"} resistance>
        { views && views.map( (view, key) => {
          const View = view
          return <View style={style.slide} key={key} />
        })}
      </SwipeableViews>
    );
  }
}

const mapStateToProps = (state) => ({
  index: state.swipeViews.index,
});

export default (connect(mapStateToProps, { setIndex })(withRouter(Views)));
