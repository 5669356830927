import React, { Component } from 'react';
import { confs } from '../conf';
import { connect } from 'react-redux';
import { fetchMessageDetail, updateMessage } from '../actions/EchangeActions';
import LoadingComponent from '../components/LoadingComponent/LoadingComponent';
import { getFrDate, getFrTime } from '../Utils';
import history from '../history';
import RippleEffect from '../components/Ripple/RippleEffect';
import PiecesDL from '../components/PiecesDL/PiecesDL';

const { PRIMARY_BG } = confs;

class MessageDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textarea_bg: 'transparent',
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.match.params.row_idfixe) {
      this.props.fetchMessageDetail(this.props.match.params.row_idfixe, this.props.access_token);
    }
  }

  update() {
    const { row_idfixe } = this.props.match.params;
    this.props.updateMessage({...this.state, row_idfixe});
    history.goBack();
  }

  render() {

    const { row_idfixe } = this.props.match.params;
    const { msg_detail, isfetching } = this.props;

    const style = {
      background: msg_detail.codope_soc? '#d7d7d7': PRIMARY_BG,
      color: msg_detail.codope_soc? 'grey': 'white',
      marginBottom: '2rem',
    };

    if (isfetching) { return <LoadingComponent />; }

    if (!isfetching && msg_detail.row_idfixe === row_idfixe) {
      return (
        <div className={"container"}>

          <div className={"row justify-content-center"}>
            <div className={"col-md-6"}>

              <div
                style={{alignItems: 'center', color: PRIMARY_BG, display: 'flex', flexDirection: 'row', justifyContent:'space-between', marginBottom: '1rem', padding: '.5rem 1rem', cursor: 'pointer'}}
              >
                <i onClick={() => history.goBack()} style={{color: PRIMARY_BG}} className={"fa fa-times icon-button"}></i>
                <div style={{display: 'flex'}}>
                  {
                    msg_detail.row_idfixemedia &&
                    <PiecesDL save={msg_detail.media.web_lu_tie !== 'O'} piece={{typ_piece: 'FIL', cod_piece: msg_detail.media.num_media}} href={msg_detail.media.url}>
                      <i  style={{color:!msg_detail.codope_soc?PRIMARY_BG:'#d7d7d7'}} className={"fa fa-download icon-button"}></i>
                    </PiecesDL>
                  }
                  {
                    !msg_detail.row_idfixemedia &&
                      <RippleEffect onClick={() => this.update()} style={{padding: '.5rem', borderRadius: '.3rem'}}>
                        Enregistrer
                      </RippleEffect>
                  }
                </div>
              </div>

            </div>
          </div>

          <div className={"row justify-content-center"}>
            <div className={"col-md-6"}>
              <div style={{padding: '0.5rem'}}>

                <div style={style} class={"talk-bubble tri-right round "+(msg_detail.codope_soc? 'btm-left-in': 'btm-right-in')}>
                  <div ref={bubble => this.bubble = bubble} class="talktext">
                    <p>
                      {
                        msg_detail.msg_contenu &&
                          <textarea
                            ref={textarea => this.textarea = textarea}
                            onFocus={() => this.setState({textarea_bg: 'white'})}
                            onBlur={() => this.setState({textarea_bg: 'transparent'})}
                            onChange={(e) => this.setState({[e.target.name]: e.target.value})}
                            name="msg_contenu"
                            rows={3}
                            col={25}
                            style={{
                              width: '100%',
                              background: this.state.textarea_bg,
                              border: 'none',
                              height: '100%',
                              color: this.state.textarea_bg==='white'?'grey': (msg_detail.codope_soc?'grey':'white'),
                            }}
                          >
                            {msg_detail.msg_contenu}
                          </textarea>
                      }
                      {
                        msg_detail.row_idfixemedia &&
                          <img src={msg_detail.media.url} className={"img-fluid"} alt=""/>
                      }
                    </p>
                  </div>
                </div>

                {
                  msg_detail.codope_soc &&
                  <p style={{margin: '0'}}>Par <strong>{msg_detail.codope_soc}</strong></p>
                }
                <p style={{margin: '0'}}>Le <strong>{getFrDate(msg_detail.msg_date)}</strong></p>
                <p style={{margin: '0'}}>A <strong>{getFrTime(msg_detail.msg_heure)}</strong></p>

              </div>
            </div>
          </div>

          <style dangerouslySetInnerHTML={{
            __html: [
               '.talk-bubble.tri-right:after {',
               msg_detail.codope_soc?'  border-color: #d7d7d7 transparent transparent #d7d7d7':'  border-color: ' + PRIMARY_BG + ' ' + PRIMARY_BG +' transparent transparent;',
               '}'
              ].join('\n')
            }}>
          </style>

        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => ({
  isfetching: state.fetching.fetching_messages,
  msg_detail: state.echanges.msg_detail,
  access_token: state.auth.access_token,
});

export default connect(mapStateToProps, { fetchMessageDetail, updateMessage })(MessageDetail);
