import React, { Component } from 'react';
import { hexToRgbA } from '../../Utils';
import { confs } from '../../conf';

const { PRIMARY_BG } = confs;

class Legend extends Component {
  render() {

    const { color, label, icon, children } = this.props;

    return (
        <div className={"item"}>
          {color && <span className={"legend-notif bg-"+(color)}></span>}
          {icon && <span className={"legend-notif"}><i style={{color: 'rgba('+hexToRgbA(PRIMARY_BG)+', 0.7)',  fontSize: '1rem'}} className={"fa fa-"+icon}></i></span>}
          <span className={"text"}>{label || children}</span>
        </div>
    );
  }
}

export default Legend;
