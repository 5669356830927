import { call, put, takeLatest } from 'redux-saga/effects';
import { files } from './api';

export function* postImage(action) {
  try {
      const newFile = yield call(files.postImage, action.payload);
      yield put({type: "POST_IMAGE_SUCCESS", payload: newFile});
   } catch (e) {
      yield put({type: "POST_IMAGE_FAILED", payload: e.message});
   }
}

export function* deleteFile(action) {
  try {
      const deleted = yield call(files.deleteFile, action.payload);
      yield put({type: "DELETE_FILE_SUCCESS", payload: deleted});
   } catch (e) {
      yield put({type: "DELETE_FILE_FAILED", payload: e});
   }
}

function* filesSaga(){
  /* Get all saga */
  yield takeLatest("POST_IMAGE_START", postImage);
  yield takeLatest("DELETE_FILE_START", deleteFile);
}

export default filesSaga;
