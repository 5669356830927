import React, { Component } from 'react';
import EchangeForm from '../components/Echanges/EchangeForm';
import Fade from '@material-ui/core/Fade';
import { Dialog } from '@material-ui/core';
import history from '../history';

function Transition(props) {
  return <Fade  {...props} />;
}

class EchangesAddPage extends Component {
  render() {
    return (
      <main id={"contact-page"}>

        <Dialog
           fullWidth
           open={true}
           TransitionComponent={Transition}
         >
           <EchangeForm />
           <button onClick={() => history.replace('/echanges')} className={"btn btn-link"}>Annuler</button>
         </Dialog>

      </main>
    );
  }
}

export default EchangesAddPage;
