import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloturerEchange } from '../../actions/EchangeActions';
import LoadingAwaitButton from '../LoadingButton/LoadingAwaitButton';
import history from '../../history';

class SoldeButton extends Component {
  constructor(props) {
    super(props);
  }

  async soldeConversation() {
    const params = {
      num_echange: this.props.echange.num_echange,
      solder_codope_tiers: this.props.user.codcli
    };

    const { access_token } = this.props.auth;
    let res = await this.props.cloturerEchange(params, access_token);
    history.push('/echanges');
  }

  render() {

    const { solder, nbr_msg_nonlu_soc, messages, lib_echange } = this.props.echange;

    return (
        <div className={"text-center"}>
          {((solder!= 'O' && nbr_msg_nonlu_soc <= 0 && lib_echange != 'Bienvenue') &&(
            <LoadingAwaitButton id={"solde-button"} onClick={()=>this.soldeConversation()}>
              Cloturer cet échange
            </LoadingAwaitButton>
          ))}
          {
            solder === 'O' &&
            <small className={"text-white"}>
              <p style={{margin : '0'}}>
                <em>Echange cloturé</em>
              </p>
              <p style={{margin : '0'}}>
              </p>
            </small>
          }
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  auth: state.auth,
  echange: state.echanges.echange,
});

export default withRouter(connect(mapStateToProps, { cloturerEchange })(SoldeButton));
