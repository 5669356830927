import axios from 'axios';
import qs from 'qs';
import { confs } from '../conf';

const { URL_API } = confs;

export const FETCH_CDES_ENCOURS = 'FETCH_CDES_ENCOURS';
export const FETCH_CDES_HISTO = 'FETCH_CDES_HISTO';
export const FETCH_CDE_DETAIL = 'FETCH_CDE_DETAIL';
export const FETCH_FACTURES = 'FETCH_FACTURES';
export const FETCH_FACTURES_HISTO = 'FETCH_FACTURES_HISTO';
export const FETCH_BES = 'FETCH_BES';
export const FETCH_BES_HISTO = 'FETCH_BES_HISTO';
export const FETCH_MEDIAS = 'FETCH_MEDIAS';
export const FETCH_MEDIAS_HISTO = 'FETCH_MEDIAS_HISTO';
export const FETCHING_CDE = 'FETCHING_CDE';
export const FETCHING_FAC = 'FETCHING_FAC';
export const FETCHING_BES = 'FETCHING_BES';
export const FETCHING_MEDIAS = 'FETCHING_MEDIAS';
export const DOWNLOAD_PIECE = 'DOWNLOAD_PIECE';
export const MEDIA_VALIDATION = 'MEDIA_VALIDATION';

export const fetchCDESEnCours = (codcli, access_token) => dispatch => {
  const params = {access_token: access_token};

  dispatch({type: FETCHING_CDE, payload: true});

  return axios.get(URL_API+'/cdes/'+codcli, {params})
  .then(res => {

    if(res.status === 200){
      return dispatch({type: FETCHING_CDE, payload: false}, dispatch({
        type: FETCH_CDES_ENCOURS,
        payload: res.data.data,
      }));
    }

 }).catch(err => {

    return dispatch({type: FETCHING_CDE, payload: null}, dispatch({type:FETCH_CDES_ENCOURS, payload: null}));

 });

};
export const fetchCDESHisto = (codcli, params) => dispatch => {

  dispatch({type: FETCHING_CDE, payload: true});

  return axios.get(URL_API+'/cdes-historique/'+codcli, {params})
  .then(res => {

    if(res.status === 200){
      return dispatch({type: FETCHING_CDE, payload: false}, dispatch({
        type: FETCH_CDES_HISTO,
        payload: res.data.data,
      }));
    }

 }).catch(err => {

    return dispatch({type: FETCHING_CDE, payload: null}, dispatch({type:FETCH_CDES_HISTO, payload: null}));

 });

};

export const fetchFacturesHisto = (codcli, params) => dispatch => {

  dispatch({type: FETCHING_FAC, payload: true});

  return axios.get(URL_API+'/factures-historique/'+codcli, {params})
  .then(res => {

    if(res.status === 200){
      return dispatch({type: FETCHING_FAC, payload: false}, dispatch({
        type: FETCH_FACTURES_HISTO,
        payload: res.data.data,
      }));
    }

 }).catch(err => {

    return dispatch({type: FETCHING_FAC, payload: null}, dispatch({type:FETCH_FACTURES_HISTO, payload: null}));

 });

};

export const fetchFactures = (codcli, parametre) => dispatch => {

  dispatch({type: FETCHING_FAC, payload: true});

  return axios.get(URL_API+'/factures/'+codcli, { params: { access_token: parametre } })
  .then(res => {

    if(res.status === 200){
      return dispatch({type: FETCHING_FAC, payload: false}, dispatch({
        type: FETCH_FACTURES,
        payload: res.data.data,
      }));
    }

 }).catch(err => {

    return dispatch({type: FETCHING_FAC, payload: null}, dispatch({type:FETCH_FACTURES, payload: null}));

 });

};
export const fetchBesHisto = (codcli, params) => dispatch => {

  dispatch({type: FETCHING_BES, payload: true});

  return axios.get(URL_API+'/bons-expeditions-historique/'+codcli, {params})
  .then(res => {

    if(res.status === 200){
      return dispatch({type: FETCHING_BES, payload: false}, dispatch({
        type: FETCH_BES_HISTO,
        payload: res.data.data,
      }));
    }

 }).catch(err => {

    return dispatch({type: FETCHING_BES, payload: null}, dispatch({type:FETCH_BES_HISTO, payload: null}));

 });

};

export const fetchMediasHisto = (codcli, params) => dispatch => {

  dispatch({type: FETCHING_MEDIAS, payload: true});

  return axios.get(URL_API+'/medias-historique/'+codcli, {params})
  .then(res => {

    if(res.status === 200){
      return dispatch({type: FETCHING_MEDIAS, payload: false}, dispatch({
        type: FETCH_MEDIAS_HISTO,
        payload: res.data.data,
      }));
    }

 }).catch(err => {

    return dispatch({type: FETCHING_MEDIAS, payload: null}, dispatch({type:FETCH_MEDIAS_HISTO, payload: null}));

 });

};

export const fetchBes = (codcli, parametre) => dispatch => {

  dispatch({type: FETCHING_BES, payload: true});

  return axios.get(URL_API+'/bons-expeditions/'+codcli, { params: { access_token: parametre } })
  .then(res => {

    if(res.status === 200){
      return dispatch({type: FETCHING_BES, payload: false}, dispatch({
        type: FETCH_BES,
        payload: res.data.data,
      }));
    }

 }).catch(err => {

    return dispatch({type: FETCHING_BES, payload: null}, dispatch({type:FETCH_BES, payload: null}));

 });

};

export const fetchMedias = (codcli, parametre) => dispatch => {

  dispatch({type: FETCHING_MEDIAS, payload: true});

  return axios.get(URL_API+'/medias/'+codcli, { params: { access_token: parametre } })
  .then(res => {

    if(res.status === 200){
      return dispatch({type: FETCHING_MEDIAS, payload: false}, dispatch({
        type: FETCH_MEDIAS,
        payload: res.data.data,
      }));
    }

 }).catch(err => {

    return dispatch({type: FETCHING_MEDIAS, payload: null}, dispatch({type:FETCH_MEDIAS, payload: null}));

 });

};

export const fetchCdeDetail = (numcdc, parametre) => dispatch => {

  dispatch({type: FETCHING_CDE, payload: true});

  return axios.get(URL_API+'/cde/'+numcdc, { params: { access_token: parametre } })
  .then(res => {
    if(res.status === 200){
      return dispatch({type: FETCHING_CDE, payload: false}, dispatch({
        type: FETCH_CDE_DETAIL,
        payload: res.data.data,
      }));
    }
 }).catch(err => {
    return dispatch({type: FETCHING_CDE, payload: null}, dispatch({type:FETCH_CDE_DETAIL, payload: null}));
 });
};


export const markAsDownloaded = (params) => dispatch => {
  return axios({
    method: 'post',
    url: URL_API+"/pieces/download",
    data: qs.stringify(params)
  })
  .then(res => {
    if(res.status === 200){
      return dispatch({
        type: DOWNLOAD_PIECE,
        payload: res.data.data,
      });
    }

   }).catch(err => {

      return dispatch({ type:DOWNLOAD_PIECE, payload: {} });

   });
}

export const mediaValidation = (params, access_token) => dispatch => {
  return axios({
    method: 'post',
    url: URL_API+"/medias/validation?access_token="+access_token,
    data: qs.stringify(params)
  })
  .then(res => {
    if(res.status === 200){
      return dispatch({
        type: MEDIA_VALIDATION,
        payload: res.data.data,
      });
    }

   }).catch(err => {

      return dispatch({ type:MEDIA_VALIDATION, payload: {} });

   });
}
