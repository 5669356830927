import React, { Component } from 'react';
import { connect } from 'react-redux';
import './pages.css';

class SubHeader extends Component {
  render() {

    const { nomcli } = this.props.user  ;

    return (
      <div className={"header--component"}>
        <div id={"logo-marchand"}>

        </div>
        {nomcli.length < 10 &&
            <h1>{nomcli}</h1>
            ||
            <h1>{nomcli.substr(0,11)+"..."}</h1>
        }
        <div></div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user
})

export default connect(mapStateToProps, {})(SubHeader);
