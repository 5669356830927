export const POST_IMAGE_START   = 'POST_IMAGE_START';
export const POST_IMAGE_SUCCESS = 'POST_IMAGE_SUCCESS';
export const POST_IMAGE_FAILED  = 'POST_IMAGE_FAILED';

export const DELETE_FILE_START   = 'DELETE_FILE_START';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAILED  = 'DELETE_FILE_FAILED';

export const postImage = (params) => ({
  type: POST_IMAGE_START, payload: params
});

export const deleteFile = (params) => ({
  type: DELETE_FILE_START, payload: params
});
