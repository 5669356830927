import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import LoadingComponent from '../components/LoadingComponent/LoadingComponent';
import history from '../history';
import { autoLogin } from '../actions/AuthActions';

class AutoAuth extends Component {

  async UNSAFE_componentWillMount() {
    if (!this.props.match.params.codcli ||!this.props.match.params.token) {
      history.replace('/login');
    } else {
      const user = {
        codcli: this.props.match.params.codcli,
        token: this.props.match.params.token
      }
      let res = await this.props.autoLogin(user);

      if (res) {
        history.replace('/');
      }
    }
  }

  render() {

    const { fetching_user, user, access_token } = this.props;

    if (fetching_user === true) { return (<LoadingComponent />); }

    if (((!user || !access_token) && (fetching_user === null))) {
      return (<Redirect to={'/login'} />);
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  access_token: state.auth.access_token,
  user: state.auth.user,
  fetching_user: state.fetching.fetching_user
});

export default connect(mapStateToProps, { autoLogin })(AutoAuth);
