import React, { Component } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Dialogues from './components/Dialogues/Dialogues';
import Snackbar from './components/Snackbar/Snackbar';
import {
  Main,
  Commandes,
  CommandeDetail,
  Factures,
  BonsExpedition,
  EchangesPage,
  Echanges,
  Login,
  PageEnConstruction,
  AutoAuth,
  EchangesAddPage,
  MessageDetail,
  AccountPage,
  ForgotPasswordPage,
} from './pages';
import {
  HOME,
  COMMANDES,
  FACTURES,
  ECHANGES,
  ECHANGE,
  LOGIN,
  PIECES_QS
} from './routes';
import history from './history';

class App extends Component {
  /*
    ** Adding scripts
  */
  render() {

    return (
      <Router history={history}>
        <>
          <Switch>

            {/* Main  */}
            <PrivateRoute exact path={ "/" } component={Main}/>

            {/* Commandes  */}
            <PrivateRoute exact path={ COMMANDES } component={Commandes}/>

            {/* Factures  */}
            <PrivateRoute exact path={ FACTURES } component={Factures}/>

            {/* Expéditions  */}
            <PrivateRoute exact path={ "/commandes/:numcdc" } component={CommandeDetail}/>

            {/* Expéditions  */}
            <PrivateRoute exact path={ "/bons-expedition" } component={BonsExpedition}/>

            {/* Echanges  */}
            <PrivateRoute exact path={ ECHANGES } component={Echanges}/>

            {/* Echange creation */}
            <PrivateRoute exact path={ "/echanges/add" } component={EchangesAddPage} />

            {/* Echange  */}
            <PrivateRoute exact path={ ECHANGE } component={EchangesPage} />

            {/* MessagesDetail  */}
            <PrivateRoute exact path={ "/message/:row_idfixe" } component={MessageDetail} />

            {/* Mon profil  */}
            <PrivateRoute exact path={ "/mon-profil/" } component={AccountPage} />

            {/* LOGIN PAGE */}
            <Route exact path={ LOGIN } component={Login} />

            {/* AUTO_LOGIN PAGE */}
            <Route exact path={ '/auto-auth/:codcli/:token' } component={AutoAuth} />

            {/* Forgot Password  */}
            <Route exact path={ "/mot-de-passe-oublie" } component={ForgotPasswordPage} />

            {/* PIECES PAGE */}
            <Route exact path={ PIECES_QS } component={PageEnConstruction} />


            {/* Default Route */}
            <Route render={() =>(<Redirect to={ "/" } />)} />

          </Switch>
          <Snackbar />
        </>
      </Router>
    );
  }
}

export default App;
