import React, { Component } from 'react';
import './style.css';

class List extends Component {
  render() {

    const { children } = this.props;

    return (
      <div className={"list--component"}>
        { children }
      </div>
    );
  }
}

export default List;
